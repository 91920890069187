import { Box } from "@mui/system";
import { DashboardLayoutContent, FooterContent, contact_us_Phone, social_media_link } from "../../../Utils/Constants";
import Facebook from "../../../assets/img/svg/facebook-v2-dark.svg";
import Instagram from "../../../assets/img/svg/instagram-v2-dark.svg";
import Linkedin from "../../../assets/img/svg/linkedin-v2-dark.svg";
import imagePath from "../../../assets/imagePath";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
const FooterV2 = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="footer">
        <Box className="container">
          <Box className="footer_box">
            <Box className={"Footer_social_media"} width={"-webkit-fill-available"} marginBottom={"50px"}>
              <img
                src={imagePath.Logo}
                alt=""
                height={"80px"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/`);
                  localStorage.removeItem("assetType");
                }}
                className="responsive-footer-logo"
              />
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={4}>
                <Box>
                  <Box className="Footer_Slogan">
                    PHONE OR TEXT:{" "}
                    <a href={`tel:519-686-1123`} style={{ textDecoration: "none", color: "#fff" }}>
                      519-686-1123
                    </a>{" "}
                  </Box>
                  <Box className="Footer_Slogan">
                    TOLL FREE:{" "}
                    <a href={`tel:800-265-5747`} style={{ textDecoration: "none", color: "#fff" }}>
                      800-265-5747
                    </a>
                  </Box>
                  <Box className="Footer_Slogan">
                    {contact_us_Phone.Email}{" "}
                    <a
                      href={`mailto:${contact_us_Phone.Email_Address}`}
                      style={{ textDecoration: "underline", color: "#fff", textTransform: "uppercase" }}
                    >
                      {contact_us_Phone.Email_Address}
                    </a>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box className="Footer_Slogan">{DashboardLayoutContent.Slogan}</Box>
              </Grid>
              <Grid item xs={12} sm={6} lg={4}>
                <Box
                  sx={{
                    paddingLeft: {
                      xs: "0px", // For extra small screens (mobile)
                      sm: "0px", // For small screens (tablets)
                      md: "0px", // For medium screens (laptops)
                      lg: "50px", // For large screens (desktops)
                    },
                  }}
                >
                  <Box className={"Footer_Follow_Us"}>{FooterContent.FOLLOW_US}</Box>
                  <Box className="Footer_social_media">
                    <Box className="Footer_social_media_box">
                      <a href={social_media_link.Facebook} target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="" className="Footer_social_media_Icon" />
                      </a>
                    </Box>
                    <Box className="Footer_social_media_box">
                      <a href={social_media_link.Instagram} target="_blank" rel="noreferrer">
                        <img src={Instagram} alt="" className="Footer_social_media_Icon" />
                      </a>
                    </Box>
                    <Box className="Footer_social_media_box">
                      <a href={social_media_link.Linkedin} target="_blank" rel="noreferrer">
                        <img src={Linkedin} alt="" className="Footer_social_media_Icon" />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className="footer_bottom_text">
          <Box className="container">
            <Box className="Footer_text">
              © {new Date().getFullYear()} · Marcel · Site by{" "}
              <span
                onClick={() => window.open(FooterContent.WEB_LINK, "_blank")}
                href={FooterContent.WEB_LINK}
                className="Footer_text_Web_Link"
                target="_blank"
              >
                {FooterContent.LINK_NAME}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default FooterV2;

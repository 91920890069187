import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import DashboardComponent from "../../components/Dashboard/DashboardComponent";
import { Box } from "@mui/system";
import EquipmentComponent from "../../components/Equipment/EquipmentComponent";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";

const InventoryEquipment = () => {
  const location = useLocation();

  // Set title and meta description based on the route path
  const getHelmetData = () => {
    switch (location.pathname) {
      case "/38-landfill-compactors":
        return {
          title: "Landfill Compactors | Reliable Used Landfill Compactors",
          description:
            "Browse Marcel Equipment's selection of high-quality used landfill compactors, reconditioned or rebuilt to handle the toughest landfill conditions with reliability.",
        };
      case "/40-waste-handler-dozers":
        return {
          title: "Waste Handler Dozers | Reconditioned for Maximum Durability",
          description:
            "Explore our waste handler dozers at Marcel Equipment, rebuilt for exceptional durability for demanding waste handling and compacting environments.",
        };
      case "/39-crawler-dozers":
        return {
          title: "Crawler Dozers for Sale | Used & Reconditioned Equipment",
          description:
            "Find reliable used and reconditioned crawler dozers at Marcel Equipment, reconditioned to tackle construction and earthmoving projects with reliability.",
        };
      case "/23-wheel-loaders":
        return {
          title: "Wheel Loaders for Sale | Heavy Equipment Solutions",
          description:
            "Discover top-quality used wheel loaders at Marcel Equipment, rebuilt to deliver dependable performance for construction, mining, and waste handling applications.",
        };
      case "/44-block-handlers":
        return {
          title: "Block Handlers for Sale | Reliable Heavy Equipment",
          description:
            "Browse our selection of block handlers, expertly reconditioned by Marcel Equipment to ensure reliable operation in block handling and material moving industries.",
        };
      case "/36-rock-trucks":
        return {
          title: "Rock Trucks & Off Highway Trucks | Heavy Duty Equipment",
          description:
            "Explore Marcel Equipment’s inventory of used rock, off highway & rigid frame trucks, reconditioned for durability and high performance in tough conditions.",
        };
      case "/35-articulated-trucks":
        return {
          title: "Articulated Dump Trucks | Used Equipment for Tough Terrain",
          description:
            "Shop our range of articulated & off highway trucks at Marcel Equipment, reconditioned to handle rough terrain and heavy loads, ensuring reliable performance.",
        };
      default:
        return {
          title: "Marcel Equipment Limited | Reconditioned Used Equipment",
          description:
            "Marcel Equipment Limited offers high-quality used heavy equipment, rebuilt & reconditioned for top performance in construction and landfill industries.",
        };
    }
  };

  const helmetData = getHelmetData();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-VWGPB9LR1K", // Replace with your Google Tag Manager ID
      dataLayerName: "PageDataLayer",
      dataLayer: {
        page: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <>
      <Helmet>
        <title>{helmetData.title}</title>
        <meta name="description" content={helmetData.description} />
      </Helmet>
      <DashboardLayout activeTab={"Home"}>
        <Box className="container">
          <EquipmentComponent />
        </Box>
      </DashboardLayout>
    </>
  );
};

export default InventoryEquipment;

import { Box, Grid } from "@mui/material";
import AboutUsBanner from "../../assets/img/images/About_us.jpg";
import { Our_Mission, What_Sets_Apart } from "../../Utils/Constants";
import { useNavigate } from "react-router-dom";
import { Path } from "../../Utils/ScreenPath";
import Mask_group from "../../assets/img/images/Mask_group.png";

const Process_Card = ({ item }) => {
  return (
    <Box className="Process_Card_Box">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box className="Process_Card_Title">{item.title}</Box>
        {item.index ? (
          <Box className="Process_Card_Index" padding={"12px"}>
            <img src={item.index} alt="" />
          </Box>
        ) : null}
      </Box>
      <Box className="Process_Card_Sub_Title">{item.subTitle}</Box>
    </Box>
  );
};

const AboutUsComponentV2 = () => {
  let navigate = useNavigate();
  return (
    <>
      <Box
        style={{
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${AboutUsBanner}) center/cover no-repeat`,
        }}
        className="bg-[image:var(--image-url)]"
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Title">
            Rebuilding Heavy Equipment, Redefining Industry Standards <br />
            <h100 className="Our_Team_Banner_Sub_Title" style={{ fontWeight: "bold", textTransform: "none" }}>
              Delivering Reconditioned Equipment with Reliability and Integrity Since 1976
            </h100>
          </Box>
        </Box>
      </Box>

      <Box
        className="Our_Team_Banner_Sub_Box"
        sx={{
          paddingTop: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
        }}
        bgcolor={"#fff"}
      >
        <Box className="container">
          <Grid container item spacing={4}>
            <Grid item xs={12} md={12} lg={3}>
              <Box className="Our_Team_Banner_Sub_Title">
                At Marcel Equipment Limited, we’ve spent over four decades building trust and delivering exceptional
                value to our customers. Specializing in high-quality reconditioned heavy equipment, we proudly serve the
                landfill, construction, aggregate, and mining industries worldwide.
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={9}>
              <Box className="Who_We_Are_Box">
                <Box className="Who_We_Are_Title">Who We Are</Box>
                <Box className="Our_Team_Banner_Sub_Title Who_We_Are_Sub_Title" color={"#fff"}>
                  Founded in 1976, Marcel Equipment Limited has grown from a local dealer to a globally respected name
                  in the heavy equipment industry. Our focus has always been on providing reconditioned machinery that
                  offers the reliability of new equipment at a fraction of the cost.
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            className="Our_Team_Banner_Sub_Box"
            sx={{
              paddingY: {
                xs: "20px", // For extra small screens (mobile)
                sm: "40px", // For small screens (tablets)
                md: "60px", // For medium screens (laptops)
                lg: "90px", // For large screens (desktops)
              },
            }}
            bgcolor={"#fff"}
          >
            <Box className="Our_Team_Members_Text" textAlign={"center"}>
              What Sets Us Apart
            </Box>

            <Grid container item spacing={4} sx={{ marginBottom: "20px" }} marginTop={"50px"}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Process_Card item={What_Sets_Apart.Rigorous_Reconditioning} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Process_Card item={What_Sets_Apart.Centric_Philosophy} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Process_Card item={What_Sets_Apart.Global_Expertise} />
              </Grid>
            </Grid>
          </Box>
          <Box
            className="Our_Team_Banner_Sub_Box"
            sx={{
              paddingBottom: {
                xs: "20px", // For extra small screens (mobile)
                sm: "40px", // For small screens (tablets)
                md: "60px", // For medium screens (laptops)
                lg: "90px", // For large screens (desktops)
              },
            }}
            bgcolor={"#fff"}
          >
            <Box className="Our_Team_Members_Text" textAlign={"center"}>
              Our Mission
            </Box>

            <Box className="Our_Team_Banner_Sub_Title" textAlign={"center"} marginTop={"20px"}>
              To empower industries with equipment that’s rebuilt to perform and backed by unparalleled expertise.
            </Box>

            <Box className="Expertise_Text" textAlign={"center"} marginTop={"20px"}>
              Our Values
            </Box>

            <Grid container item spacing={4}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Process_Card item={Our_Mission.Quality} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Process_Card item={Our_Mission.Transparency} />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Process_Card item={Our_Mission.Customer_Focus} />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          className="Our_Team_Banner_Sub_Box"
          sx={{
            paddingY: {
              xs: "20px", // For extra small screens (mobile)
              sm: "40px", // For small screens (tablets)
              md: "60px", // For medium screens (laptops)
              lg: "90px", // For large screens (desktops)
            },
          }}
          bgcolor={"#F5F5F5"}
        >
          <Box className="container">
            <Grid container item spacing={3} sx={{ marginBottom: "20px" }}>
              <Grid item xs={12} md={12} lg={6}>
                <Box className="about-us-box" marginBottom={"24px"}>
                  <Box className="Who_We_Are_Title" color={"#141215"}>
                    Our People Make the Difference
                  </Box>
                  <Box className="Our_Team_Banner_Sub_Title" marginTop={"30px"}>
                    At Marcel Equipment Limited, we know that delivering exceptional equipment starts with having an
                    exceptional team. From our knowledgeable sales experts to our skilled technicians, every team member
                    plays a vital role in ensuring your success. Learn more about the people behind the machines on our{" "}
                    <h100
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        navigate(Path.OurTeam);
                      }}
                      style={{ cursor: "pointer", color: "#FACD40", fontWeight: "bold", textDecoration: "underline" }}
                    >
                      Meet Our Team
                    </h100>{" "}
                    page.
                  </Box>
                </Box>
                <Box className="about-us-box" marginBottom={"24px"}>
                  <Box className="Who_We_Are_Title" color={"#141215"}>
                    Built on a Strong Foundation
                  </Box>
                  <Box className="Our_Team_Banner_Sub_Title" marginTop={"30px"}>
                    Our success is rooted in a meticulous reconditioning process that transforms used equipment into
                    reliable, work-ready machines. From thorough inspections to final performance tests, our approach
                    ensures that every piece of machinery we sell is ready to meet the demands of your toughest
                    projects. Learn more about our approach on the Reconditioning Process page.{" "}
                    <h100
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        navigate(Path.ReconditioningProcess);
                      }}
                      style={{ cursor: "pointer", color: "#FACD40", fontWeight: "bold", textDecoration: "underline" }}
                    >
                      Reconditioning Process
                    </h100>{" "}
                    page.
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={6}>
                <Box display={"flex"} flexDirection={"column"} height={"100%"}>
                  <Box className="about-us-box" marginBottom={"24px"} height={"min-content"}>
                    <Box className="Who_We_Are_Title" color={"#141215"}>
                      Our Commitment to Excellence
                    </Box>
                    <Box className="Our_Team_Banner_Sub_Title" marginTop={"30px"}>
                      "Doing It Right Today… To Earn Your Business Tomorrow" is more than a slogan—it’s our promise to
                      provide equipment and services that exceed expectations, every time.
                    </Box>
                  </Box>
                  <Box height={"100%"}>
                    <img
                      src={Mask_group}
                      alt=""
                      style={{ width: "100%", objectFit: "cover", height: "calc(100% - 24px)" }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Box className="about-us-box" marginBottom={"24px"} bgcolor={"#FACD40"} textAlign={"center"}>
              <Box className="Who_We_Are_Title" color={"#141215"}>
                How We Can Help You
              </Box>
              <Box className="Our_Team_Banner_Sub_Title" marginTop={"30px"}>
                Whether you’re searching for reliable heavy equipment or expert advice, Marcel Equipment Limited is here
                to support you every step of the way. Let us help you tackle your next project with confidence and the
                tools you need to succeed.
                <br />
                <br />
                <h100
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    navigate(Path.ContactUs);
                  }}
                  style={{ cursor: "pointer", color: "#141215", fontWeight: "bold", textDecoration: "underline" }}
                >
                  {" "}
                  Contact Us
                </h100>{" "}
                today to learn more or get started.
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default AboutUsComponentV2;

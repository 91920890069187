import { Box, Button, Divider, Grid } from "@mui/material";
import imagePath from "../../assets/imagePath";
import PrintIcon from "../../assets/img/svg/print-icon-small-black.svg";
import CopyIcon from "../../assets/img/svg/copy-btn.svg";
import { dashboard_navigon_link_list, Inventory_Content, Inventory_Details } from "../../Utils/Constants";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getAllMachinesList, getMachineDetailsByID } from "../../services/machineService";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { t } from "i18next";
import ReactToPrint from "react-to-print";
import moment from "moment/moment";
import { Img } from "react-image";
import pdfFile from "../../assets/img/svg/pdf-file.svg";
import ShhopingCardIcon from "../../assets/img/svg/shopping-cart.svg";
import ShhopingCardDarkIcon from "../../assets/img/svg/shopping-cart-dark.svg";
import PulseIcon from "../../assets/img/svg/pulse.svg";
import MinusIcon from "../../assets/img/svg/minus.svg";
import BackArrow from "../../assets/img/svg/back-arrow.svg";
import { Path } from "../../Utils/ScreenPath";

const ComponentToPrint = React.forwardRef((props, ref) => {
  const numberWithUSDFormat = (number) => {
    // Format number as USD
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };
  return (
    <Box ref={ref} padding={"20px"} display={"flex"} flexDirection={"column"}>
      {props?.print && (
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            {moment(new Date()).format("M/DD/YYYY")} , {moment().format("h:mm a")}
          </Box>
          <Box>
            {props?.props?.model?.manufacturer?.name} {props?.props?.model?.number} - Marcel Equipment
          </Box>
        </Box>
      )}
      <Box padding={"10px"}>
        {props?.print && (
          <Box display={"flex"}>
            <img src={imagePath.Logo} alt="" height="40px" style={{ cursor: "pointer" }} />
          </Box>
        )}

        <table>
          <tr>
            <th colspan="2">
              <Box className="Inventory_Title_Print">
                {props?.props?.model?.manufacturer?.name} {props?.props?.model?.number}{" "}
                {props?.props?.year ? `(${props?.props?.year})` : null}{" "}
                {props?.props?.unit_number ? ` UNIT # ${props?.props?.unit_number}` : null}
              </Box>
              <Box className="Inventory_HOURS_Box">
                {props?.props.meter_reading ? (
                  <Box className="Inventory_Title_Print mr-2">
                    {t("Dashboard_Content_MACHINE_HOURS")}: {props?.props.meter_reading}
                  </Box>
                ) : (
                  <Box className="Inventory_Title_Print">{t("Dashboard_Content_MACHINE_HOURS")}: N/A</Box>
                )}
                {props?.props.MACHINE_HOURS ? (
                  <Box className="Inventory_Title_Print mr-2">
                    {Inventory_Content.MACHINE_HOURS}:{props?.props.MACHINE_HOURS}
                  </Box>
                ) : null}{" "}
                {props?.props.idle_hours ? (
                  <Box className="Inventory_Title_Print mr-2">
                    {Inventory_Content.ECM_IDLE_HOURS}:{props?.props.idle_hours}
                  </Box>
                ) : null}
                {props?.props.load_factor ? (
                  <Box className="Inventory_Title_Print mr-2">
                    {Inventory_Content.LOAD_FACTOR}:{props?.props.load_factor}
                  </Box>
                ) : null}
                {props?.props.neutral_hours ? (
                  <Box className="Inventory_Title_Print mr-2">
                    {Inventory_Content.NEUTRAL_HOURS}:{props?.props.neutral_hours}
                  </Box>
                ) : null}
              </Box>
            </th>
          </tr>
          <tr>
            <td className="InventoryComponent_left_side_box_Print">
              <Box>
                <Box padding={"0 20px 20px 20px"} textAlign={"center"}>
                  <img src={props?.props?.primary_image?.url} style={{ minWidth: "340px", maxWidth: "340px" }} />
                </Box>
                <Box className="Report_Text">Repair / Condition Report</Box>
                <Box display={"flex"}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props?.props?.repair_condition_html,
                    }}
                    className="repair_html_text_copy"
                  ></div>
                </Box>
              </Box>
            </td>
            <td className="InventoryComponent_right_side_box_Print">
              <Box className="">
                <Box>
                  <Box>
                    <Box>
                      <Box className="InventoryComponent_Price_Box">
                        <Box className="InventoryComponent_Price_Print_text">{Inventory_Content.Price}:</Box>
                        <Box className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                          {props?.props?.advertised_price?.currency?.symbol}
                          {props?.props?.advertised_price?.fractional
                            ? props?.props?.advertised_price?.fractional === "0"
                              ? "0.00"
                              : numberWithUSDFormat(props?.props?.advertised_price?.fractional).replace("$", "")
                            : "0.00"}
                          <Box className="InventoryComponent_Price_Print_Code">
                            {props?.props?.advertised_price?.currency?.iso_code}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="InventoryComponent_Price_Box">
                        <Box className="InventoryComponent_Price_Print_text">{Inventory_Content.Price}:</Box>
                        <Box className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                          {props?.props?.alternate_price?.currency?.symbol}
                          {props?.props?.alternate_price?.fractional
                            ? props?.props?.alternate_price?.fractional === "0"
                              ? "0.00"
                              : numberWithUSDFormat(props?.props?.alternate_price?.fractional).replace("$", "")
                            : "0.00"}
                          <Box className="InventoryComponent_Price_Print_Code">
                            {props?.props?.alternate_price?.currency?.iso_code
                              ? props?.props?.alternate_price?.currency?.iso_code
                              : "USD"}
                          </Box>
                        </Box>
                      </Box>

                      {/* <Box className="InventoryComponent_Price_Box">
                        <Box className="InventoryComponent_Price_Print_text">Year:</Box>
                        <Box className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                          {props?.props?.year}
                        </Box>
                      </Box>
                      <Box className="InventoryComponent_Price_Box">
                        <Box className="InventoryComponent_Price_Print_text">Hours:</Box>
                        <Box className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                          {props?.props?.meter_reading}
                        </Box>
                      </Box>
                      <Box className="InventoryComponent_Price_Box">
                        <Box className="InventoryComponent_Price_Print_text">Make:</Box>
                        <Box className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                          {props?.props?.manufacturer?.name}
                        </Box>
                      </Box>
                      <Box className="InventoryComponent_Price_Box">
                        <Box className="InventoryComponent_Price_Print_text">MODEL:</Box>
                        <Box className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                          {props?.props?.model?.number}
                        </Box>
                      </Box> */}
                    </Box>

                    <Box className="Description_Box" textAlign={"start"}>
                      <Box className={props?.print ? "Print_Description_Title" : "Description_Title"}>
                        {Inventory_Content.Description}
                      </Box>
                      <Box className="Description_Box_Text">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: props?.props?.machine_description_html,
                          }}
                          className="description_html_text"
                        ></div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </td>
          </tr>
        </table>
      </Box>
    </Box>
  );
});

const ComponentToCopy = React.forwardRef((props, ref) => {
  const numberWithUSDFormat = (number) => {
    // Format number as USD
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };
  let imageUrl = props?.props?.primary_image?.url;

  const htmlContentrepair = `
    <!-- 
    Online HTML, CSS and JavaScript editor to run code online.
    -->
    <!DOCTYPE html>
    <html lang="en">
    
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="stylesheet" href="style.css" />
      <title>Browser</title>
    </head>
    
    <body>
      <div id="container">
      ${props?.props?.repair_condition_html}
      </div>
      <script src="script.js"></script>
    </body>
    
    </html>
  `;
  const htmlContentdescription = `
  <!-- 
  Online HTML, CSS and JavaScript editor to run code online.
  -->
  <!DOCTYPE html>
  <html lang="en">
  
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="stylesheet" href="style.css" />
    <title>Browser</title>
  </head>
  
  <body>
    <div id="container">
    ${props?.props?.machine_description_html}
    </div>
    <script src="script.js"></script>
  </body>
  
  </html>
`;

  return (
    <Box ref={ref} padding={"20px"} display={"flex"} flexDirection={"column"} textAlign={"start"} maxWidth={"900px"}>
      <div>
        <Box className="Inventory_Title_Copy">
          {props?.props?.model?.manufacturer?.name} {props?.props?.model?.number}{" "}
          {props?.props?.year ? `(${props?.props?.year})` : null}{" "}
          {props?.props?.unit_number ? ` UNIT # ${props?.props?.unit_number}` : null}
        </Box>
        <Box className="Inventory_HOURS_Box" width={"-webkit-fill-available"}>
          {props?.props.meter_reading ? (
            <span className="Inventory_Title_Copy mr-2">
              {t("Dashboard_Content_MACHINE_HOURS")}: {props?.props.meter_reading}
            </span>
          ) : (
            <span className="Inventory_Title_Copy">{t("Dashboard_Content_MACHINE_HOURS")}: N/A</span>
          )}
          {props?.props.MACHINE_HOURS ? (
            <span className="Inventory_Title_Copy mr-2">
              {Inventory_Content.MACHINE_HOURS}:{props?.props.MACHINE_HOURS}
            </span>
          ) : null}{" "}
          {props?.props.idle_hours ? (
            <span className="Inventory_Title_Copy mr-2">
              {Inventory_Content.ECM_IDLE_HOURS}:{props?.props.idle_hours}
            </span>
          ) : null}
          {props?.props.load_factor ? (
            <span className="Inventory_Title_Copy mr-2">
              {Inventory_Content.LOAD_FACTOR}:{props?.props.load_factor}
            </span>
          ) : null}
          {props?.props.neutral_hours ? (
            <span className="Inventory_Title_Copy mr-2">
              {Inventory_Content.NEUTRAL_HOURS}:{props?.props.neutral_hours}
            </span>
          ) : null}
        </Box>
      </div>
      <table
        style={{
          maxWidth: "100%",
          display: "table",
          textIndent: "initial",
          verticalAlign: "text-top",
        }}
      >
        <tbody>
          <tr style={{ display: "flex", flexDirection: "row" }}>
            <tr style={{ display: "flex", flexDirection: "row" }}>
              <td className="InventoryComponent_left_side_box_Copy" style={{ verticalAlign: "text-top" }} colSpan={1}>
                <Box>
                  <Box
                    padding={"0 20px 20px 20px"}
                    style={{
                      verticalAlign: "text-top",
                      textAlign: "-webkit-center",
                    }}
                  >
                    <Img src={imageUrl} width={250} />
                  </Box>
                  <Box className="Report_Text">Repair / Condition Report</Box>
                  <Box display={"flex"}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: htmlContentrepair,
                      }}
                      className="repair_html_text_copy"
                    ></div>
                  </Box>
                </Box>
              </td>
              <td className="InventoryComponent_left_side_box_Copy" style={{ verticalAlign: "text-top" }} colSpan={1}>
                <div className="">
                  <Box
                    className="Description_Box"
                    textAlign={"start"}
                    sx={{
                      minHeight: "150px",
                      padding: "0rem 1.25rem",
                      textAlign: "left",
                      // maxWidth: "500px",
                      // minWidth: "400px",
                      lineHeight: "1.1em",
                      maxWidth: "300px",
                    }}
                  >
                    <div>
                      <div className="InventoryComponent_Price_Box">
                        <span className="InventoryComponent_Price_Print_text">
                          {Inventory_Content.Price} :
                          <span className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                            {props?.props?.advertised_price?.currency?.symbol}
                            {props?.props?.advertised_price?.fractional
                              ? props?.props?.advertised_price?.fractional === "0"
                                ? "0.00"
                                : numberWithUSDFormat(props?.props?.advertised_price?.fractional).replace("$", "")
                              : "0.00"}
                            <span className="InventoryComponent_Price_Print_Code">
                              {" "}
                              {props?.props?.advertised_price?.currency?.iso_code}
                            </span>
                          </span>
                        </span>
                      </div>
                      <div className="InventoryComponent_Price_Box">
                        <span className="InventoryComponent_Price_Print_text">
                          {Inventory_Content.Price} :
                          <span className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                            {props?.props?.alternate_price?.currency?.symbol}
                            {props?.props?.alternate_price?.fractional
                              ? props?.props?.alternate_price?.fractional === "0"
                                ? "0.00"
                                : numberWithUSDFormat(props?.props?.alternate_price?.fractional).replace("$", "")
                              : "0.00"}
                            <span className="InventoryComponent_Price_Print_Code">
                              {" "}
                              {props?.props?.alternate_price?.currency?.iso_code
                                ? props?.props?.alternate_price?.currency?.iso_code
                                : "USD"}
                            </span>
                          </span>
                        </span>
                      </div>
                      {/* <div className="InventoryComponent_Price_Box">
                        <span className="InventoryComponent_Price_Print_text">
                          Year :
                          <span className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                            {props?.props?.year}
                          </span>
                        </span>
                      </div>
                      <div className="InventoryComponent_Price_Box">
                        <span className="InventoryComponent_Price_Print_text">
                          Hours :
                          <span className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                            {props?.props?.meter_reading}
                          </span>
                        </span>
                      </div>
                      <div className="InventoryComponent_Price_Box">
                        <span className="InventoryComponent_Price_Print_text">
                          Make :
                          <span className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                            {props?.props?.manufacturer?.name}
                          </span>
                        </span>
                      </div>
                      <div className="InventoryComponent_Price_Box">
                        <span className="InventoryComponent_Price_Print_text">
                          MODEL :
                          <span className="InventoryComponent_Price_Box InventoryComponent_Print_Price">
                            {props?.props?.model?.number}
                          </span>
                        </span>
                      </div> */}
                    </div>
                    <Box className={props?.print ? "Print_Description_Title" : "Description_Title"}>
                      {Inventory_Content.Description}
                    </Box>
                    <Box
                      className="Description_Box_Text"
                      sx={{
                        ol: {
                          div: {
                            ul: {
                              listStyleType: "disc",
                              p: {
                                wordWrap: "break-word",
                                fontSize: "16px",
                                lineHeight: "1.1em",
                              },
                            },
                          },
                        },
                        // div: {
                        //   ul: {
                        //     padding: '0px',
                        //     li: {
                        //       padding: '0px'
                        //     },
                        //   },
                        //   ol: {
                        //     padding: '0px',
                        //     li: {
                        //       padding: '0px'
                        //     },
                        //     // ul:{
                        //       // listStyleType: 'inherit'
                        //     // }
                        //   }
                        // }
                      }}
                    >
                      <div
                        style={{
                          minHeight: "150px",
                          // padding: "0rem 1.25rem",
                        }}
                      >
                        {/* <ol> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: htmlContentdescription,
                          }}
                          className="description_html_text_Print"
                        ></div>
                        {/* </ol> */}
                      </div>
                    </Box>
                  </Box>
                </div>
              </td>
            </tr>
          </tr>
        </tbody>
      </table>
    </Box>
  );
});

const InventoryComponentV2 = (props) => {
  const { state } = useLocation();
  let { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [images, setImages] = useState([]);
  const [AssetTypeList, setAssetTypeList] = useState([]);
  const [reportText, setReportText] = useState(false);
  const [assetCategorizationSummaryLoading, setAssetCategorizationSummaryLoading] = useState(false);

  const GetMachinesTypeList = (val) => {
    setAssetCategorizationSummaryLoading(true);
    getAllMachinesList()
      .then((res) => {
        const WithoutAttachmentsList = res?.data;

        setAssetTypeList(WithoutAttachmentsList);
        setAssetCategorizationSummaryLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const CarouselStyle = {
    width: "1080px",
    height: "600px",
    thumbnailWidth: "100px",
    captionStyle: {
      display: "none",
    },
    layout: {
      textAlign: "center",
      maxWidth: "1080px",
      maxHeight: "100%",
      minHeight: "100%",
      margin: "50px auto 90px auto",
    },
  };
  const getMachineDetails = () => {
    setIsLoading(true);
    // getMachineDetailsByID(2271)
    const decode = decodeURIComponent(id);
    let splitid = decode.split("-");
    getMachineDetailsByID(state?.assetAdvertisementId ? state?.assetAdvertisementId : splitid?.[0])
      .then((res) => {
        let showImgList = [];
        const sortedData = [...res?.data?.images].sort((a, b) => a?.order - b?.order);

        sortedData?.map?.((item, index) => {
          showImgList[index] = {
            original: item?.url,
            thumbnail: item?.url,
          };
        });
        setImages(showImgList);
        setData(res?.data);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getMachineDetails();
    GetMachinesTypeList();
  }, []);

  const getCategoryList = (data) => {
    const CategoryData = data.find((item) => item.AssetTypeId === 2);
    // Order of categories you specified
    const desiredOrder = [38, 40, 39, 23, 44, 36, 35, 41];

    const filteredCategories = [];
    CategoryData?.CategoryDetails.forEach((item) => {
      const indexInDesiredOrder = desiredOrder.indexOf(item?.CategoryId);
      if (indexInDesiredOrder !== -1) {
        // If the item is found in the desiredOrder, add it to filteredCategories
        filteredCategories[indexInDesiredOrder] = item;
      }
    });

    const resultCategories = filteredCategories.filter((item) => item !== undefined);

    return resultCategories || [];
  };

  const FullScreenButton = ({ onClick, isFullScreen }) => {
    return isFullScreen ? (
      <button
        type="button"
        className="image-gallery-icon image-gallery-fullscreen-button"
        onClick={onClick}
        aria-label="Open Fullscreen"
      >
        Open
      </button>
    ) : (
      <button
        type="button"
        className="image-gallery-icon image-gallery-fullscreen-button Closed close_box"
        onClick={onClick}
        aria-label="Close Fullscreen"
      >
        <OpenInFullIcon />
      </button>
    );
  };

  const numberWithUSDFormat = (number) => {
    // Format number as USD
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(number);
  };

  const componentRef = useRef();
  const componentCopyRef = useRef();
  const Copy = () => {
    props.setSelectPage(!props.selectPage);
    const range = document.createRange();
    range.selectNode(componentCopyRef.current);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  };
  return (
    <>
      <Grid container spacing={4} mt={props.selectPage ? "20px" : "20px"}>
        <Grid
          item
          xs={12}
          md={12}
          lg={3}
          sx={{
            paddingTop: "20px",
            display: {
              xs: "none", // For extra small screens (mobile)
              sm: "none", // For small screens (tablets)
              md: "none", // For medium screens (laptops)
              lg: "block", // For large screens (desktops)
            },
          }}
        >
          <Box
            className="inventory_category"
            bgcolor={"#fff"}
            position={"relative"}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate(Path?.Category);
            }}
          >
            <Box position={"absolute"}>
              <img src={BackArrow} alt="" />
            </Box>
            BACK tO LISTING PAGE
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={9} style={{ paddingTop: "20px" }}>
          <Box textAlign={"end"} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
            <Box mr={"10px"} sx={{ cursor: "pointer" }} className="d-flex">
              <img src={CopyIcon} alt="" onClick={Copy} />
            </Box>
            <ReactToPrint
              trigger={() => {
                return <img src={PrintIcon} alt="" style={{ cursor: "pointer" }} />;
              }}
              content={() => componentRef.current}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={3}
          sx={{
            paddingTop: "20px",
            display: {
              xs: "block", // For extra small screens (mobile)
              sm: "block", // For small screens (tablets)
              md: "block", // For medium screens (laptops)
              lg: "none", // For large screens (desktops)
            },
          }}
        >
          <Box
            className="inventory_category"
            bgcolor={"#fff"}
            position={"relative"}
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              navigate(Path?.Category);
            }}
          >
            <Box position={"absolute"}>
              <img src={BackArrow} alt="" />
            </Box>
            BACK tO LISTING PAGE
          </Box>
        </Grid>
      </Grid>
      <Box pt={!props.selectPage ? "0" : "0"}>
        {isLoading && assetCategorizationSummaryLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginTop: "30px",
              height: "60vh",
              alignItems: "center",
            }}
          >
            <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
          </div>
        ) : (
          <Grid
            container
            item
            spacing={4}
            sx={{
              marginTop: {
                xs: "0px", // For extra small screens (mobile)
                sm: "0px", // For small screens (tablets)
                md: "0px", // For medium screens (laptops)
                lg: "0px", // For large screens (desktops)
              },
            }}
          >
            {!props.selectPage && (
              <Grid item xs={12} md={12} lg={3} style={{ paddingTop: "20px" }}>
                <Box className="inventory_category_box">
                  {getCategoryList(AssetTypeList).map((link) => {
                    return (
                      <Box
                        className={
                          data?.categorization?.category?.id == link?.CategoryId
                            ? "inventory_category_active"
                            : "inventory_category"
                        }
                        onClick={() =>
                          navigate(`/${link?.CategoryId}-${link?.CategoryName.toLowerCase().replaceAll(" ", "-")}`)
                        }
                      >
                        {link.CategoryName}
                      </Box>
                    );
                  })}
                </Box>
              </Grid>
            )}

            {!props.selectPage && (
              <Grid item xs={12} md={12} lg={6} style={{ paddingTop: "20px" }}>
                <Box>
                  <Box className="Inventory_Title">
                    {data?.model?.manufacturer?.name} {data?.model?.number} {data?.year ? `(${data?.year})` : null}{" "}
                    {data?.unit_number ? ` UNIT # ${data?.unit_number}` : null}
                  </Box>

                  <Box className="Inventory_HOURS_Box">
                    {data.meter_reading ? (
                      <Box className="Inventory_sub_Title mr-2">
                        {t("Dashboard_Content_MACHINE_HOURS")}: {data.meter_reading}
                      </Box>
                    ) : (
                      <Box className="Inventory_sub_Title">{t("Dashboard_Content_MACHINE_HOURS")}: N/A</Box>
                    )}
                    {data.MACHINE_HOURS ? (
                      <Box className="Inventory_sub_Title mr-2">
                        {Inventory_Content.MACHINE_HOURS}:{data.MACHINE_HOURS}
                      </Box>
                    ) : null}{" "}
                    {data.idle_hours ? (
                      <Box className="Inventory_sub_Title mr-2">
                        | {Inventory_Content.ECM_IDLE_HOURS}:{data.idle_hours}
                      </Box>
                    ) : null}
                    {data.load_factor ? (
                      <Box className="Inventory_sub_Title mr-2">
                        | {Inventory_Content.LOAD_FACTOR}:{data.load_factor}
                      </Box>
                    ) : null}
                    {data.neutral_hours ? (
                      <Box className="Inventory_sub_Title mr-2">
                        | {Inventory_Content.NEUTRAL_HOURS}:{data.neutral_hours}
                      </Box>
                    ) : null}
                  </Box>

                  <Box>
                    <ImageGallery
                      items={images}
                      infinite={true}
                      autoPlay={true}
                      useBrowserFullscreen={false}
                      originalWidth={CarouselStyle.width}
                      originalHeight={CarouselStyle.height}
                      slideInterval={5000}
                      showPlayButton={false}
                      renderFullscreenButton={(onClick, isFullscreen) => (
                        <FullScreenButton onClick={onClick} isFullscreen={isFullscreen} />
                      )}
                    />
                  </Box>
                  <Box className="Report_Box">
                    <Box display={"flex"} alignItems={"cente"} justifyContent={"space-between"}>
                      <Box>
                        <Box className="Report_Text">{Inventory_Details.Details.Title}</Box>
                      </Box>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          className="PulseIcon_Box"
                          onClick={() => {
                            setReportText(!reportText);
                          }}
                        >
                          <img src={!reportText ? PulseIcon : MinusIcon} alt="" style={{ cursor: "pointer" }} />
                        </Box>
                      </Box>
                    </Box>
                    {reportText && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data?.repair_condition_html,
                        }}
                        className="repair_html_text"
                        style={{ marginTop: "20px" }}
                      ></div>
                    )}
                  </Box>
                </Box>
              </Grid>
            )}

            {!props.selectPage && (
              <Grid item xs={12} md={12} lg={3} style={{ paddingTop: "20px" }}>
                <Box className="price_box" mb={"100px"}>
                  <Box>
                    <Box className="InventoryComponent_Price_Box">
                      <Box className="InventoryComponent_Price_text">{Inventory_Content.Price}:</Box>
                      <Box className="InventoryComponent_Price_Box InventoryComponent_Price">
                        {data?.advertised_price?.currency?.symbol}
                        {data?.advertised_price?.fractional
                          ? data?.advertised_price?.fractional === "0"
                            ? "0.00"
                            : numberWithUSDFormat(data?.advertised_price?.fractional).replace("$", "")
                          : "0.00"}
                        <Box className="InventoryComponent_Price_Code">
                          {data?.advertised_price?.currency?.iso_code}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="InventoryComponent_Price_Box" mt="-10px">
                      <Box className="InventoryComponent_Price_text">{Inventory_Content.Price}:</Box>
                      <Box className="InventoryComponent_Price_Box InventoryComponent_Price">
                        {data?.alternate_price?.currency?.symbol}
                        {data?.alternate_price?.fractional
                          ? data?.alternate_price?.fractional === "0"
                            ? "0.00"
                            : numberWithUSDFormat(data?.alternate_price?.fractional).replace("$", "")
                          : "0.00"}
                        <Box className="InventoryComponent_Price_Code">
                          {data?.alternate_price?.currency?.iso_code
                            ? data?.alternate_price?.currency?.iso_code
                            : "USD"}
                        </Box>
                      </Box>
                    </Box>

                    {/* <Box className="InventoryComponent_Price_Box" mt="10px">
                      <Box className="InventoryComponent_Price_text">Year:</Box>
                      <Box className="InventoryComponent_Price_Box InventoryComponent_Price">{data?.year}</Box>
                    </Box>
                    <Box className="InventoryComponent_Price_Box" mt="-10px">
                      <Box className="InventoryComponent_Price_text">Hours:</Box>
                      <Box className="InventoryComponent_Price_Box InventoryComponent_Price">{data?.meter_reading}</Box>
                    </Box>
                    <Box className="InventoryComponent_Price_Box" mt="-10px">
                      <Box className="InventoryComponent_Price_text">Make:</Box>
                      <Box className="InventoryComponent_Price_Box InventoryComponent_Price">
                        {data?.manufacturer?.name}
                      </Box>
                    </Box>
                    <Box className="InventoryComponent_Price_Box" mt="-10px">
                      <Box className="InventoryComponent_Price_text">MODEL:</Box>
                      <Box className="InventoryComponent_Price_Box InventoryComponent_Price">{data?.model?.number}</Box>
                    </Box> */}
                  </Box>
                  <Box className="Get_Submit_btn_Box" mt={"40px"}>
                    <a
                      className="get_more_info"
                      style={{ background: "#141215", color: "#fff", width: "auto" }}
                      href="mailto:sales@marcelequipment.com"
                    >
                      <img src={ShhopingCardIcon} alt="" style={{ marginRight: "10px", marginTop: "-5px" }} /> REQUEST A
                      QUOTE
                    </a>

                    <a
                      className="get_more_info"
                      style={{ background: "#fff", color: "#141215", border: "1px solid #141215", width: "auto" }}
                      // onClick={() => {
                      //   const encodeURICom = encodeURIComponent(
                      //     `${data.assetAdvertisementId}-${data?.year || ""}-${data?.manufacturer?.name}-${
                      //       data?.model?.number
                      //     }`
                      //   );
                      //   // history.push({
                      //   //   pathname: `/contact-us/${encodeURICom}`,
                      //   //   state: { id: data },
                      //   // });

                      //   navigate(`/contact-us/${encodeURICom}`, {
                      //     state: encodeURICom,
                      //   });
                      // }}
                      href="mailto:sales@marcelequipment.com"
                    >
                      <img src={ShhopingCardDarkIcon} alt="" style={{ marginRight: "10px", marginTop: "-5px" }} />{" "}
                      REQUEST TO RENT
                    </a>

                    <button
                      className="get_more_info"
                      onClick={() => {
                        const encodeURICom = encodeURIComponent(
                          `${data.assetAdvertisementId}-${data?.year || ""}-${data?.manufacturer?.name}-${
                            data?.model?.number
                          }`
                        );
                        // history.push({
                        //   pathname: `/contact-us/${encodeURICom}`,
                        //   state: { id: data },
                        // });

                        navigate(`/contact-us/${encodeURICom}`, {
                          state: encodeURICom,
                        });
                      }}
                    >
                      Get More informaiton
                    </button>
                  </Box>

                  <Box
                    sx={{ color: "#000000" }}
                    width={"-webkit-fill-available"}
                    height={"1px"}
                    bgcolor={"#000"}
                    marginY={"40px"}
                  />

                  <Box>
                    <Box className="Description_Box">
                      <Box className="Process_Card_Title" padding={"0px"} marginBottom={"20px"}>
                        {Inventory_Content.Description}
                      </Box>
                      <Box className="">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.machine_description_html,
                          }}
                          className="description_html_text"
                        ></div>
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{ color: "#000000" }}
                    width={"-webkit-fill-available"}
                    height={"1px"}
                    bgcolor={"#000"}
                    marginY={"40px"}
                  />
                  <Box className="Get_Submit_btn_Box">
                    <Box className="Documents_specifications ">Documents & specifications</Box>
                    <Box mt={"15px"}>
                      {data && data?.documents && data?.documents?.length <= 0 && <span>*Documents not available</span>}
                    </Box>

                    <Box className="Get_Document_Box">
                      {data?.documents?.map((document, key) => {
                        return (
                          <Box mb="5px">
                            <ReactTooltip id="my-tooltip" />
                            <a
                              href={document?.url}
                              download
                              // style={{ color: "#2200CC",display:'flex',justifyContent:'left',alignItems:'center'}}
                              data-tooltip-id="my-tooltip"
                              data-tooltip-content={document?.name}
                              target="_blank"
                              class="Get_Document_link"
                            >
                              {document?.name && (
                                <Box>
                                  <img data-tip={document?.name} src={pdfFile} width={"60px"} className="mr-2" alt="" />
                                  {/* <span>{document?.name}</span> */}
                                </Box>
                              )}
                              <Box sx={{ lineBreak: "anywhere" }} fontSize={"12spx"}>
                                {document?.name}
                              </Box>
                            </a>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}

            <div style={{ display: "none" }}>
              <ComponentToPrint ref={componentRef} props={data} print />
            </div>
            <div style={{ display: props.selectPage ? "block" : "none", width: "100%", textAlign: "center" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ComponentToCopy ref={componentCopyRef} props={data} copy />
              </div>
            </div>
          </Grid>
        )}
      </Box>
    </>
  );
};
export default InventoryComponentV2;

import { Box } from "@mui/system";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { DashboardLayoutContent, dashboard_navigon_link_list } from "../../Utils/Constants";
import imagePath from "../../assets/imagePath";
import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MachineListComponent from "./MachineListComponent/MachineListComponent";
import { useTranslation } from "react-i18next";
import { getAllMachinesList, postMachinesList } from "../../services/machineService";
import { ApplicationId } from "../../api/url";
import { useLocation, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Path } from "../../Utils/ScreenPath";
import HomepageBanner from "../../assets/img/images/Homepage_Banner.JPG";
import C23 from "../../assets/img/images/C-23.png";
import C35 from "../../assets/img/images/C-35.png";
import C36 from "../../assets/img/images/C-36.png";
import C38 from "../../assets/img/images/C-38.png";
import C39 from "../../assets/img/images/C-39.png";
import C40 from "../../assets/img/images/C-40.png";
import C44 from "../../assets/img/images/C-44.png";
import ContactUSIcon from "../../assets/img/svg/Contact_US.svg";
import { Grid, Skeleton } from "@mui/material";

const imageMap = {
  23: C23,
  35: C35,
  36: C36,
  38: C38,
  39: C39,
  40: C40,
  44: C44,
};

const DashboardComponentV2 = ({ AttachmentsListPage }) => {
  const { state } = useLocation();
  const [showSliderModel, setShowSliderModel] = useState(true);
  const [assetStartingLoader, setAssetStartingLoader] = useState(true);
  const [assetCategorizationSummaryLoading, setAssetCategorizationSummaryLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectCategoryType, setSelectCategoryType] = useState();
  const [AssetTypeList, setAssetTypeList] = useState([]);
  // -----------
  const [pageRefresh, setPageRefresh] = useState(false);
  const [assetTypeId, setAssetTypeId] = useState("");
  const [machineTypeSelectIdCategoryName, setMachineTypeSelectIdCategoryName] = useState("");
  const [allFilterID, setAllFilterID] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [page, setPage] = useState(1);
  const [isMachineData, setIsMachineData] = useState([]);
  const [dataLoadLoading, setDataLoadLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [machinesTypeList, setMachinesTypeList] = useState([]);
  const [categoryofMachineList, setCategoryofMachineList] = useState();

  const limit = 9;
  const navigate = useNavigate();
  const absoluteBoxRef = useRef(null);

  const [assetList, setAssetList] = useState({
    ShowTitle: "",
    selectAssetDetails: {},
    CategoryDetails: {},
    Tier1Details: [],
    Tier1Select: {},
    Tier2Details: [],
    Tier2Select: {},
    Tier3Details: [],
    Tier3Select: {},
    Tier4Details: [],
    Tier4Select: {},
    Tier5Details: [],
    Tier5Select: {},
    tierCount: 0,
    TierData: false,
    ShowBackBTN: false,
    ShowTitle: "",
  });
  const CarouselNextRef = useRef();
  const CarouselPrevRef = useRef();
  const { t } = useTranslation();

  const GetMachinesList = () => {
    setIsLoading(true);
    let payload = {
      ApplicationId: ApplicationId,
      IsFeatured: true,
    };
    postMachinesList(payload)
      .then((res) => {
        let showImgList = [];
        res?.data?.machines?.map?.((item, index) => {
          showImgList[index] = {
            ...showImgList[index],
            image: item?.primary_image?.url,
            assetAdvertisementId: item?.assetAdvertisementId,
            name: `${item.year} ${item?.primary_image?.name}`,
            ImgInfo: item,
          };
        });
        setImages(showImgList);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    GetMachinesList();
    if (window.location.href.split("userID=")?.[1]) {
      localStorage.setItem("UserID", window.location.href.split("userID=")?.[1]);
    }
  }, []);

  useEffect(() => {
    GetMachinesTypeList();
  }, []);
  const GetMachinesTypeList = (val) => {
    setAssetCategorizationSummaryLoading(true);
    getAllMachinesList()
      .then((res) => {
        const WithoutAttachmentsList = res?.data;
        setAssetTypeList(WithoutAttachmentsList);
        setAssetCategorizationSummaryLoading(false);
      })
      .catch((err) => console.log(err));
  };

  // ----------------
  const GetMachinesListSidebar = (val) => {
    if (isMachineData?.length === 0) {
      setIsLoading(true);
    }
    setDataLoadLoading(true);
    setCategoryofMachineList(undefined);
    let payload;

    if (allFilterID) {
      payload = {
        AssetTypeId: assetTypeId && assetTypeId !== undefined ? JSON.parse(assetTypeId) : null,
        PageSize: limit,
        PageNumber: pageNumber,
        // IsMachineListPage: true,
        Tier1Id: JSON.parse(allFilterID[2]),
        Tier2Id: JSON.parse(allFilterID[3]),
        Tier3Id: JSON.parse(allFilterID[4]),
        CategoryId: JSON.parse(allFilterID[5]),
      };
    } else if (val) {
      // ------------------ Split zero CategoryName ------------------
      // ------------------ Split One AssetTypeId --------------------
      // ------------------ Split two Tier1Id --------------------
      // ------------------ Split three Tier2Id --------------------
      // ------------------ Split four Tier3Id ------------------
      // ------------------ Split five CategoryId ------------------
      const IDs = val?.split(",");
      payload = {
        AssetTypeId: JSON.parse(IDs?.[1]) ?? null,
        PageSize: limit,
        PageNumber: pageNumber,
        // IsMachineListPage: true,
        Tier1Id: JSON.parse(IDs?.[2]) ?? null,
        Tier2Id: JSON.parse(IDs?.[3]) ?? null,
        Tier3Id: JSON.parse(IDs?.[4]) ?? null,
        CategoryId: JSON.parse(IDs?.[5]) ?? null,
      };
    } else {
      payload = {
        AssetTypeId: assetTypeId,
        PageSize: limit,
        PageNumber: pageNumber,
        // IsMachineListPage: true,
      };
    }
    payload = { ...payload, IsOldiesByGoodies: false, SortColumn: "SortOrder ASC" };

    payload = {
      ...payload,
      ApplicationId: ApplicationId,
    };
    postMachinesList(payload)
      .then((res) => {
        const machineData = res && res.data;
        setTotalRecords(machineData?.TotalRecords);
        if (parseInt(machineData?.PageNumber) == 1) {
          setIsMachineData(machineData?.machines);
          setPage(machineData?.PageNumber);
        } else {
          setIsMachineData([...isMachineData, ...machineData?.machines]);
          setPage(machineData?.PageNumber);
        }
        setIsLoading(false);
        setAssetStartingLoader(false);
        setDataLoadLoading(false);
      })
      .catch((err) => {
        setAssetStartingLoader(false);
      });
  };
  const handleOnChangeTypePreviousPageDetails = (e) => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    const IDs = e.split(",");
    setPageNumber(1);
    setAllFilterID(IDs);
    setMachineTypeSelectIdCategoryName(IDs[0]);
    setAssetTypeId(IDs[1]);
    GetMachinesListSidebar(e);
  };

  const handleOnChangeType = (e) => {
    // ------------------ Split zero CategoryName ------------------
    // ------------------ Split One AssetTypeId --------------------
    // ------------------ Split two Tier1Id --------------------
    // ------------------ Split three Tier2Id --------------------
    // ------------------ Split four Tier3Id ------------------
    // ------------------ Split five CategoryId ------------------
    setIsMachineData([]);
    setPage(1);
    if (e !== "") {
      const IDs = e.split(",");
      setPageNumber(1);
      setAllFilterID(IDs);
      setMachineTypeSelectIdCategoryName(IDs[0]);
      if (IDs[1] !== undefined) {
        setAssetTypeId(IDs[1]);
      }
    } else {
      setAllFilterID(null);
      setMachineTypeSelectIdCategoryName("");
      setAssetTypeId("");
    }
  };

  const handelSelectAssetTypeList = async (item, Type) => {
    setSelectCategoryType(item);
    // resetPagination();
    if (Type?.Type === "AssetList") {
      setAssetList({
        ...assetList,
        ShowTitle: item?.AssetTypeName,
        selectAssetDetails: item,
        ShowBackBTN: true,
      });
      handleOnChangeType(`${null},${item?.AssetTypeId},${null},${null},${null},${null}`);
    } else if (Type?.Type === "CategoryDetails") {
      handleOnChangeType(`${item?.CategoryName},${2},${null},${null},${null},${item?.CategoryId}`);
      return setAssetList({
        ...assetList,
        CategoryDetails: item,
        ShowTitle: item?.CategoryName,
        Tier1Details: item?.Tier1Details,
        tierCount: assetList?.tierCount + 1,
        TierData: true,
      });
    } else if (Type?.Type === "TierData") {
      if (
        item?.[`Tier${assetList?.tierCount + 1}Details`] !== undefined &&
        item?.[`Tier${assetList?.tierCount + 1}Details`]?.length !== 0
      ) {
        switch (assetList?.tierCount) {
          case 0:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${
                assetList?.selectAssetDetails?.AssetTypeId
              },${null},${null},${null},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 1:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${
                item?.Tier1Id
              },${null},${null},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 2:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${
                assetList?.Tier1Select?.Tier1Id
              },${item?.Tier2Id},${null},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 3:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id},${item?.Tier3Id},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              tierCount: assetList?.tierCount + 1,
              TierData: true,
            });
          default:
            return;
        }
      } else {
        switch (assetList?.tierCount) {
          case 0:
            return setAssetList({
              ...assetList,
              Tier1Details: item?.Tier1Details,
              TierData: true,
            });
          case 1:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier1Name,
              Tier1Select: item,
              Tier2Details: item?.Tier2Details,
              TierData: true,
            });
          case 2:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier2Name,
              Tier2Select: item,
              Tier3Details: item?.Tier3Details,
              TierData: true,
            });
          case 3:
            handleOnChangeType(
              `${assetList?.CategoryDetails?.CategoryName},${assetList?.selectAssetDetails?.AssetTypeId},${assetList?.Tier1Select?.Tier1Id},${assetList?.Tier2Select?.Tier2Id},${item?.Tier3Id},${assetList?.CategoryDetails?.CategoryId}`
            );
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier3Name,
              Tier3Select: item,
              Tier4Details: item?.Tier4Details,
              TierData: true,
            });
          case 4:
            return setAssetList({
              ...assetList,
              ShowTitle: item?.Tier4Name,
              Tier4Select: item,
              Tier5Details: item?.Tier5Details,
              TierData: true,
            });
          default:
            return;
        }
      }
    }
  };

  useEffect(() => {
    if (
      !AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      // ------------------ categoryofMachineList zero AssetTypeId ------------------
      // ------------------ categoryofMachineList One CategoryId --------------------
      // ------------------ categoryofMachineList two Tier1Id --------------------
      // ------------------ categoryofMachineList three Tier2Id --------------------
      // ------------------ categoryofMachineList four Tier3Id ------------------
      // ------------------ categoryofMachineList Five Tier4Id ------------------
      // ------------------ categoryofMachineList Six Category Name ------------------

      handleOnChangeTypePreviousPageDetails(
        `${categoryofMachineList[6] ?? null},${categoryofMachineList[0] ?? null},${categoryofMachineList[2] ?? null},${
          categoryofMachineList[3] ?? null
        },${categoryofMachineList[4] ?? null},${categoryofMachineList[1] ?? null}`
      );
    } else if (
      AttachmentsListPage &&
      machinesTypeList?.length !== 0 &&
      categoryofMachineList &&
      categoryofMachineList?.length !== 0 &&
      categoryofMachineList !== undefined
    ) {
      setMachineTypeSelectIdCategoryName(categoryofMachineList[1] ?? "");
      GetMachinesListSidebar(categoryofMachineList[1] ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machinesTypeList, categoryofMachineList]);

  useEffect(() => {
    if (allFilterID) {
      GetMachinesListSidebar();
    }
  }, [pageNumber, machinesTypeList, allFilterID, machineTypeSelectIdCategoryName]);

  useEffect(() => {
    if (state) {
      setIsLoading(true);
      setIsMachineData([]);
      setPage(1);
      handelSelectAssetTypeList(state, {
        Type: "CategoryDetails",
      });
    }
  }, []);
  const getCategoryList = (data) => {
    const CategoryData = data.find((item) => item.AssetTypeId === 2);
    // Order of categories you specified
    const desiredOrder = [38, 40, 39, 23, 44, 36, 35, 41];

    const filteredCategories = [];
    CategoryData?.CategoryDetails.forEach((item) => {
      const indexInDesiredOrder = desiredOrder.indexOf(item?.CategoryId);
      if (indexInDesiredOrder !== -1) {
        // If the item is found in the desiredOrder, add it to filteredCategories
        filteredCategories[indexInDesiredOrder] = item;
      }
    });

    const resultCategories = filteredCategories.filter((item) => item !== undefined);

    return resultCategories || [];
  };

  return (
    <>
      <Box>
        <Box
          className={"dashboard_banner"}
          sx={{
            // background: `url(${HomepageBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${HomepageBanner}) center/cover no-repeat`,
          }}
        >
          <Box className="container">
            <Box
              textAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              className="banner_component"
            >
              <Box className="home_bannar_text">Marcel Equipment Limited | Quality Reconditioned Used Equipment</Box>
              <Box className="home_bannar_sub_text">
                Leading Independent Dealer of High-Quality Rebuilt/Reconditioned Equipment Since 1976
              </Box>
              <Box
                className="home_bannar_btn"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(Path?.Category);
                }}
              >
                Explore Our Inventory{" "}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="container">
          <Box position={"relative"}>
            <Box
              bottom={"100px"}
              position={"absolute"}
              style={{ background: "#FACD40", width: "-webkit-fill-available" }}
              textAlign={"center"}
              ref={absoluteBoxRef}
              className="home_banner_box"
            >
              <Box className="explore_title" mb={"20px"}>
                {DashboardLayoutContent.Slogan}
              </Box>
              <Box className="explore_sub_title" mb={"20px"}>
                {DashboardLayoutContent.Slogan_1}
              </Box>
              <Box className="explore_sub_title">{DashboardLayoutContent.Slogan_2}</Box>
            </Box>
          </Box>

          <Box marginY={"100px"} position={"relative"} marginTop={`${absoluteBoxRef?.current?.offsetHeight}px`}>
            <Box className="explore_title" textAlign={"center"}>
              Explore Marcel
            </Box>

            {assetCategorizationSummaryLoading || isLoading ? (
              <Grid item container spacing={2} sx={{ mt: "10px" }}>
                {Array.from({ length: 8 }).map(() => {
                  return (
                    <Grid item xs={12} sm={6} lg={3}>
                      <Box display="flex" flexDirection="column" height="100%">
                        <Box flex={1} display="flex">
                          <Skeleton variant="rectangular" width={"100%"} height={250} />
                        </Box>
                        <Box sx={{ pt: 0.5 }}>
                          <Skeleton width="100%" />
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            ) : !assetCategorizationSummaryLoading && !isLoading ? (
              <Grid item container spacing={2} sx={{ mt: "10px" }}>
                {getCategoryList(AssetTypeList).map((link) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      lg={3}
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
                        navigate(`/${link?.CategoryId}-${link?.CategoryName.toLowerCase().replaceAll(" ", "-")}`);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <Box display="flex" flexDirection="column" height="100%">
                        <Box flex={1} display="flex">
                          <img
                            src={imageMap[link?.CategoryId]}
                            alt=""
                            width="100%"
                            height="100%"
                            style={{ objectFit: "cover", objectPosition: "center" }}
                          />
                        </Box>
                        <Box className="category_title">{link.CategoryName}</Box>
                      </Box>
                    </Grid>
                  );
                })}

                <Grid
                  item
                  xs={12}
                  sm={6}
                  lg={3}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    navigate(Path.ContactUs);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                    <Box
                      flex={1}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      bgcolor="#141215"
                      width={"100%"}
                      className="home_page_contact_us"
                    >
                      <img
                        src={ContactUSIcon}
                        alt=""
                        width="100px"
                        height="100px"
                        style={{ objectFit: "cover", objectPosition: "center" }}
                      />
                    </Box>
                    <Box className="category_title" bgcolor="#141215" color="#fff" width={"-webkit-fill-available"}>
                      Contact US
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashboardComponentV2;

import imagePath from "../assets/imagePath";
import SalesRentals from "../assets/img/svg/Sales_Rentals.svg";
import TechnicalSupport from "../assets/img/svg/Technical_Support.svg";
import CustomerService from "../assets/img/svg/Customer_Service.svg";
import MarkFrederick from "../assets/img/images/Mark-profile-img.jpg";
import JohnStewart from "../assets/img/images/John-profile-img.jpg";
import ManishChopra from "../assets/img/images/ManishChopra.png";
import RigorousReconditioning from "../assets/img/svg/RigorousReconditioning.svg";
import CentricPhilosophy from "../assets/img/svg/CentricPhilosophy.svg";
import GlobalExpertise from "../assets/img/svg/GlobalExpertise.svg";
import Quality from "../assets/img/svg/Quality.svg";
import Transparency from "../assets/img/svg/Transparency.svg";
import CustomerFocus from "../assets/img/svg/CustomerFocus.svg";
import AndreaStone from "../assets/img/images/Andrea Stone.png";
import PAULPRINCE from "../assets/img/images/PAUL_PRINCE.jpg";
import LORANZOKIMPE from "../assets/img/images/LORANZO_KIMPE.png";
import MapPin from "../assets/img/svg/map-pin-fill.svg";
import Size from "../assets/img/svg/Size.svg";

import CHUCK_DIETRICH from "../assets/img/images/Mechanic/CHUCK_DIETRICH.png";
import AUSTIN_COOK from "../assets/img/images/Mechanic/AUSTIN_COOK.png";
import ERIC_MCLEAN from "../assets/img/images/Mechanic/ERIC_MCLEAN.png";
import JONATHAN_WENINGER from "../assets/img/images/Mechanic/JONATHAN_WENINGER.png";
import JACOB_DAWSON from "../assets/img/images/Mechanic/JACOB_DAWSON.png";
import TYSON_KANA from "../assets/img/images/Mechanic/TYSON_KANA.png";
import JON_GENTER from "../assets/img/images/Mechanic/JON_GENTER.png";
import MICHAEL_GUSTEN from "../assets/img/images/Mechanic/MICHAEL_GUSTEN.png";
import BRENT_JACKSON from "../assets/img/images/Mechanic/BRENT_JACKSON.png";
import DOMINIC_KISIEL from "../assets/img/images/Mechanic/DOMINIC_KISIEL.png";

export const ReCAPTCHA_sitekey = "6Ld8hx4pAAAAAOnDKuofE10gPgNROp_os0gxetWb";
export const DashboardLayoutContent = {
  Slogan: "DOING IT RIGHT TODAY…TO EARN YOUR BUSINESS TOMORROW.",
  Slogan_1:
    "Based in Ontario, Canada, Marcel Equipment specializes in buying and selling heavy construction and landfill equipment, with a strong focus on earthmoving and waste handling.",
  Slogan_2:
    "We deliver high-quality, reconditioned equipment to industries like construction, mining, aggregate, and landfill operations.",
  BtnContent: {
    EN: "EN",
    ES: "ES",
    FR: "FR",
  },
};

export const HeaderNavigationLabel = {
  Home: "Home",
  Reconditioning_Process: "Reconditioning Process",
  Our_Team: "Our Team",
  About_Us: "About Us",
  Gallery: "Gallery",
  Links: "Links",
  Contact_Us: "Contact Us",
};

export const ContactDetels = {
  PH_TXT: "PH/TXT: 519-686-1123",
  TF_PH: "TF - PH:  800-265-5747",
};

export const FooterContent = {
  SITS_BY: "© 2022 · Marcel · Site by",
  WEB_LINK: "https://vizybilitydms.com/",
  LINK_NAME: "Vizybility DMS",
  FOLLOW_US: "FOLLOW US",
};

export const social_media_link = {
  Facebook: "https://www.facebook.com/Marcel-Equipment-Limited-839883946137208/",
  Instagram: "https://www.instagram.com/marcelequipment",
  Linkedin: "https://www.linkedin.com/company/marcel-equipment-ltd",
};

export const dashboard_navigon_link_list = [
  {
    title: "LANDFILL COMPACTORS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "WASTE HANDLER DOZERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "CRAWLER DOZERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "WHEEL DOZERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "WHEEL LOADERS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "ROCK TRUCKS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "ARTICULATED TRUCKS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
  {
    title: "EXCAVATORS",
    MachineList: [
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2018) UNIT # C6783",
        Hours: "9875",
        description:
          "Stop & Flood Light Arrangement, Front Wheel Cleaners, STIC Steering System, Auto Reverse Cooling Fan, Air Turbine Engine Pre-Cleaner, Pressurized Cab Pre-Cleaner",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836K (2016) UNIT # C5768",
        Hours: "13015",
        description:
          "CAT Diamond Compaction Wheels, Front & Rear No Spin Differentials, Air Turbine Engine Pre-Cleaner, Stop & Flood Light Arrangment, Ansul Fire Suppression CAT U Landfill Blade",
      },
      {
        img: imagePath.Machine,
        ModelName: "CAT 836H (2011) UNIT # C0217",
        Hours: "11560",
        description:
          "CAT Step Tip Compaction Wheels, CAT SU Landfill Blade, Pressurized Cab Pre-Cleaner, Stop & Flood Light Arrangement, Hydraulic Controlled Belly Pans, STIC Steering",
      },
    ],
  },
];

export const contact_us_content = {
  Next_Construction: "Are you looking for equipment for your next construction project or landfill?",

  Equipment_Needs: "We can assist with your equipment needs.",

  Address_Below: "Send us an email at the address below.",

  Equipment_Searching: "We'd like to hear from you and help source the equipment you are searching for.",
};

export const contact_us_address = {
  Marcel_Equipment_Limited: "Marcel Equipment Limited",
  Progress_Drive: "1000 Progress Drive",
  London: "London, Ontario",
  N6N_1B8: "N6N 1B8",
  Canada: "Canada",
};

export const contact_us_Phone = {
  Contact_John: "Contact John or Mark",
  Phone: "Phone or Text: 519-686-1123",
  Fax: "Fax: 519-686-9350",
  Toll_Free: "Toll Free: 800-265-5747",
  Email: "Email:",
  Email_Address: "sales@marcelequipment.com",
  PHONE_String: "PHONE:",
  TEXT_String: "TEXT:",
  NUMBER_1: "519-686-1123",
  NUMBER_2: "800-265-5747",
};

export const About_Us_content = {
  About_Us: "About  Us",
  Title1:
    "Renting equipment is a cost-effective alternative to purchasing and allows you to meet those peak production periods for your jobsites. It also allows you to bid on jobs that you might not otherwise bid on if you did not have the required equipment within your own fleet. We can offer flexible rental periods such as monthly, weekly and even hourly under certain conditions.",
  Title2:
    "Marcel Equipment also provides Rental with Purchase Option (RPO). Why not try it before you buy it! With an RPO option, you get to know the machine better than we do. You can put it to work, ensure it does the job for your application. It puts the risk on Marcel Equipment should an issue arise with the machine, so it is not at your cost. Come the end of the rental term, you have the option to return it if not completely satisfied. If it does what we said it would and meets your needs, you can convert it to a purchase and already have some equity/down payment built in by applying the paid rentals rent to the purchase price.",
};

export const About_Us_second_content = {
  Title1: "End of Lease, Trade-In, or Surplus Equipment?",
  Title2:
    "80% of the time we offer more than the lease return/buy out price or trade-in value if we can be part of your bidding process. ",

  Title3:
    "Whether you are returning a leased Landfill Compactor or Waste Handler Crawler tractor or offering it as a Trade-in, we would welcome the opportunity from you to be able to offer you a purchase price for the equipment or to participate in your bidding process.",

  Title4:
    "So whether you are looking to Buy or Sell Landfill or Construction equipment, Marcel Equipment has the Quality Inventory, Experienced staff and the Expertise to accommodate your needs.",

  Title5:
    "Please call Mark or John today at: 1-800-265-5747 or call or text to 1-519-686-1123 or via email to: sales@marcelequipment.com",

  Title6: "Thank you for your interest in Marcel Equipment Limited and we look forward to working with you.",
};

export const Our_Team_content = {
  Title1: "Mark Frederick — General Manager",
  TitleSub1:
    "Mark has over 25 years of experience in the heavy equipment industry. He was always passionate about heavy equipment, right from a toddler who began with Tonka trucks in the sandbox, to fueling and greasing machines when he was old enough to work for his relatives in the construction industry and eventually operating equipment.",

  TitleSub2:
    "After going to University for his BBA, he knew a job in the financial and conventional business environment was not where his passion was. His passion was to be in the heavy equipment industry. As a result, he made the decision to go to college to become a heavy equipment technician. After college, he accepted a job with the local Cat dealer where he managed to skip getting his hands dirty and went into their Management Trainee program. Mark spent close to 15 years working at the Cat dealership through various jobs on the product support side of the business.",

  TitleSub3:
    "In 2014, Marcel Equipment approached him with an opportunity to join the company. It was a tough decision to leave a company that provided him with many great opportunities and one that he thought he would retire from. However, in the end and already having a close relationship with Marcel Equipment, the move made sense for him and his family and he joined the team. He believes in doing things right the first time; as well as being fair and honest, which is a perfect fit for the trustworthy reputation of Marcel Equipment.  He works by the motto…would you feel comfortable selling this piece of equipment to your own father.",

  TitleSub4:
    "Today, he manages the business at Marcel Equipment for Groupe Poisson and loves coming into work each day to face the rewards and challenges of the industry.",

  TitleSub5:
    "Mark and his wife Tiffany have two young children. His hobbies include playing hockey, fishing and snowmobiling.",

  TitleSub6:
    "Mark looks forward to continuing the tradition of providing quality used equipment for Marcel Equipment and building upon the Marcel Equipment reputation and name that has been built over the past 45 years.",
};

export const Our_Team_second_content = {
  Title1: "John Stewart – Sales Manager",
  TitleSub1:
    "John has 50 years of experience in the heavy equipment Sales and Servicing industry. He is a licensed Heavy Equipment Technician by trade and uses that experience everyday sourcing and selling equipment for Marcel Equipment Limited.",

  TitleSub2:
    "He has been the Sales Manager of Marcel Equipment Ltd. for over 25 years and has a great knowledge of all types of construction equipment, especially the Landfill Compactors and Waste Handler Crawler Tractors that Marcel Equipment specializes in.",

  TitleSub3:
    "He is an old farm boy that grew up operating and fixing all types of farm and construction equipment. In his off time, he enjoys fishing, landscaping, remodeling properties and beekeeping, which he says teaches him patience.",

  TitleSub4: "John has three grown sons & several grandchildren to enjoy",

  TitleSub5:
    "John welcomes your calls and inquiries, be it to discuss an application, what type of equipment to specify in your bid documents or to purchase a machine. In a complex industry with many options, let him help you in determining the best possible equipment to complete the work most effectively.",

  TitleSub6:
    "We hope you enjoy working with Mark and John who are good, honest people who make it easy to do business with Marcel Equipment.",
};

export const Reconditioning_Process_content = {
  Title1: "Marcel Equipment’s Machine Preparation Processes",

  TitleSub1:
    "Buying used heavy equipment can be a nerve-racking experience even for the most experienced purchaser. Questions spin around your head; do you know who you are buying from, are they well known in the industry, do they have any references, how long have they been in business, do they own the piece of equipment they are selling, do they have any previous history on the machine, how can I be confident in the machine I am buying, was it an insurance write-off, will I get what they say I will, is it as good as they are saying it is, why does it not come with any warranty?",

  TitleSub2:
    "We at Marcel Equipment are here to take all that guess work away from you and eliminate the potential nerve-racking experience. Let us do the worrying for you and take on the risk.",

  TitleSub3:
    "At Marcel Equipment, each employee takes pride in the product we sell. We stand behind our product and everyone of our employees are proud of each machine that leaves here with the Marcel Equipment name on it. ",

  TitleSub4:
    "We are fully transparent in everything we do.  We provide complete and factual details on each machine including visual inspection results, operational testing results, pressure testing results, current and previous repair history, Cat undercarriage inspection reports, pictures and specifications. We take pride in providing you with complete details on everything we know. We go above and beyond anyone else in the industry to ensure that you know the full details on what you are purchasing. Nothing leaves our yard with a known issue. We complete all needed repairs the right way with no short cuts.  We can provide many references from our past customers whom have purchased one unit from us or multiple units over the years.",

  TitleSub5:
    "In order to eliminate risks for both ourselves and the end user, we follow stringent processes for each machine. That is why we have developed and fine tuned our reconditioning process over the last 45 years.",
};

export const Reconditioning_Process_second_content = {
  Title1: "MARCEL EQUIPMENT’S RECONDITIONING PROCESS",

  TitleSub1:
    "Prior to purchase, we complete background history checks and/or have the machines inspected in order to weed out any machine that may have any serious mechanical problems or be from a previous detrimental application.",

  TitleSub2:
    "Once the machine arrives to our yard, all panels and covers are removed and machines are completely power washed.",

  TitleSub3: "Our shop foreman then completes his inspection, listing any items that require attention or repair.",

  TitleSub4:
    "Machines are then put through a complete operational testing process and visual inspection by our mechanics.",

  TitleSub5: "They are then serviced and all filters are cut open to inspect for contaminates.",

  TitleSub6: "All suction screens, magnetic plugs and strainers are checked closely for contaminates.",
  TitleSub7: "Non-filtered oils are also checked for contaminates and discolouration.",
  TitleSub8:
    "Pressure checks are completed and adjusted on the engine, transmission and all hydraulic systems to ensure they are within OEM spec.",
  TitleSub9:
    "Performance checks are completed such as; engine high and low idle speeds, turbo boost, torque converter stall speeds and engine performance to ensure correct engine horsepower as well as to ensure the transmission and torque converter are operating at optimum efficiency.",
  TitleSub10:
    "Once the inspection and servicing are complete, all required repairs are completed which may include but not limited to;",

  SubTitle10point1: "Electrical system",
  SubTitle10point2: "Monitoring system",
  SubTitle10point3: "Fuel system",
  SubTitle10point4: "Steering system",
  SubTitle10point5: "Air intake and Exhaust system",
  SubTitle10point6: "Cooling system",
  SubTitle10point7: "Changing of hoses, tubes and lines",
  SubTitle10point8: "Hydraulic cylinders",
  SubTitle10point9: "Air conditioning and heating system",
  SubTitle10point10: "Glass replacement",
  SubTitle10point11: "Operator station repairs",
  SubTitle10point12: "Undercarriage repairs and replacement",
  SubTitle10point13: "Joint wear at pins and bushings",
  SubTitle10point14: "Line boring of joint pin bosses",
  SubTitle10point15: "Axle oscillation points",
  SubTitle10point16: "Grease systems and/or lines",
  SubTitle10point17: "All needed repairs are completed by our knowledgeable staff.",
  SubTitle10point18: "Machines are then operated and rechecked for any further required repairs.",
  SubTitle10point19:
    "Machines then move to our welding & cosmetic repair shop where welding repairs are completed to the frame structures.",
  SubTitle10point20: "Railings, steps and sheet metal repairs are completed.",
  SubTitle10point21:
    "Machines are then taped up, sandblasted, primed, painted and new decals installed in our painting facility.",
  SubTitle10point22: "Our shop foreman completes one last visual and operational inspection.",
  SubTitle10point23: "Machines then go through our Pre-Delivery Inspection (PDI) before delivery takes place.",
  SubTitle10point24:
    "At the end of the inspection, the technician is asked…Would you be happy if you purchased this machine? The unit will not be shipped until the answer is YES.  ",

  TitleSub11:
    "This process ensures machines are work ready and in top operating condition when delivered to our customers.",

  TitleSub12: "This is our Process… ",
  TitleSub12point1: "“Doing it Right Today to Earn Your Business Tomorrow”",
};

export const Reconditioning_Process_third_content = {
  Slogan: "Our Unique Approach",
  Slogan_2:
    "At Marcel Equipment, we're not your typical used equipment dealer. With over 48 years of experience, we've developed a comprehensive reconditioning process that sets us apart in the industry. Our goal is to provide you with work-ready reconditioned heavy equipment, eliminating the guesswork and risk often associated with purchasing used machinery.",
  Title1: "REBUILD AND RECONDITIONING PROCESS",

  TitleSub1: `The primary benefit of purchasing a piece of rebuilt equipment is cost savings. In many cases the price on a rebuilt machine is significantly less than that of a new machine. Looking at it another way, you can sometimes buy two pieces of reconditioned equipment for the same amount of money you would spend on one new machine. You can end up with equipment that is "like new" at far less cost and, in the process, improve the reliability and availability of your fleet.`,

  TitleSub2: "Before deciding if rebuilt equipment is right for you, it is important to consider many factors.",

  TitleSub3:
    "Rebuilt equipment is used equipment that has undergone steps to offset the effects of wear and tear. Unlike most used equipment, rebuilt equipment is refurbished to improve its condition. However, the degree to which a machine is reconditioned depends on two things: the vendor that is performing the process and the standards and guidelines they follow.",

  TitleSub4:
    "Like the definition of rebuilt, the scope of work varies. The best rebuilding and reconditioning programs include a rigorous process that performs a complete teardown of the machine and looks at all the major systems: electrical, hydraulic, drivetrain, structural components, operator station, hoses and cylinders. ",

  TitleSub5:
    "Carefully consider whom the provider is as you shop for rebuilt/reconditioned equipment.  Begin your search by asking for a detailed description of the scope of work the provider performs. What items in the major systems are typically replaced, rebuilt or repaired? What systems or components are not replaced? Are machine parts rebuilt by an OEM or to OEM standards?  What is the previous history on the machine? Do they have a serial number of the machine? An important part of any rebuild program is to start with a good core machine.",

  TitleSub6:
    "Finally, consider the experience of the provider - how long have they rebuilt equipment, how many machines have they rebuilt, and how frequently do they rebuild equipment? It is also a good idea to ask for and check several references to be sure they will attest to the quality of the provider's work.",
  TitleSub7:
    "Let Marcel Equipment answer all those questions for you and instil the confidence you need to make an informed and educated decision.    ",
  TitleSub8: "At Marcel Equipment, we have been rebuilding Landfill Compactors and Dozers for over 40 years.",
  TitleSub9:
    "Our experienced tradespeople know what to look for, what areas are problematic, what needs to be completed to increase reliability and how to produce an end product that can go right to work with no issues.",
  TitleSub10:
    "The rebuilt units go through our typical reconditioning process but can also include rebuilding or replacement of the machines;",

  SubTitle10point1: "Engine",
  SubTitle10point2: "Torque convertor",
  SubTitle10point3: "Transmission",
  SubTitle10point4: "Transfer case",
  SubTitle10point5: "Drive line",
  SubTitle10point6: "Hydraulic pumps and motors",
  SubTitle10point7: "Complete fuel systems",
  SubTitle10point8: "Wiring Harnesses",
  SubTitle10point9: "Cooling assemblies",
  SubTitle10point10: "Final drives",
  SubTitle10point11: "Axles",
  SubTitle10point12: "Hydraulic cylinders",
  SubTitle10point13: "Cab reconditioning",
  TitleSub11:
    "We can offer Marcel Equipment Rebuilds using our experienced technicians like we have done for years with great success.",
  TitleSub12: "Or",
  TitleSub13:
    "In conjunction with our local Cat dealer, we can offer units with Cat Certified Rebuild Machine Components (CMCR) which can carry extended warranties supported by Cat dealers all over North America. In working with the Cat dealer, the engine, torque, transmission and transfer case are rebuilt at their rebuild facility. The components are rebuilt to strict guidelines and procedures for Cat Certified specifications and bench/dyno tested upon completion.",
  TitleSub14: "Marcel Equipment Limited is… ",
  TitleSub14spoint1: "“Doing it Right Today to Earn Your Business Tomorrow”",
};

export const Link_List = {
  Logo1Link: "https://www.groupepoisson.com/",
  Logo2Link: "https://selectcivil.com.au/",
  Logo3Link: "https://www.iedagroup.com/",
  Logo4Link: "https://marcelequipment.com/links/www.terracompactorwheel.com",
  Logo5Link: "https://swana.org/",
  Logo6Link: "https://aors.on.ca/",
  Logo7Link: "https://www.owma.org/cpages/home",
  Logo8Link: "https://municipalwaste.ca/",
  Logo9Link: "http://ldhca.com/",
  Logo11Link: "https://pipeline.ca/",
  Logo12Link: "https://www.shearpowercorp.com/",
  Logo13Link: "https://www.equipmentjournal.com/",
};

export const Gallery_List = [
  {
    logo: imagePath.GalleryLogo1,
    link: "https://www.instagram.com/p/CapuaZ9qjt6/",
  },
  {
    logo: imagePath.GalleryLogo2,
    link: "https://www.instagram.com/p/Caaqg9sqk70/",
  },
  {
    logo: imagePath.GalleryLogo3,
    link: "https://www.instagram.com/p/CaPYbIBKgxE/",
  },
  {
    logo: imagePath.GalleryLogo4,
    link: "https://www.instagram.com/p/CaFNruIqFbK/",
  },
  {
    logo: imagePath.GalleryLogo5,
    link: "https://www.instagram.com/p/CZ1unvNKXe-/",
  },
  {
    logo: imagePath.GalleryLogo6,
    link: "https://www.instagram.com/p/CZkZUFrKaQ6/",
  },
  {
    logo: imagePath.GalleryLogo7,
    link: "https://www.instagram.com/p/CZR09RKqVWf/",
  },
  {
    logo: imagePath.GalleryLogo8,
    link: "https://www.instagram.com/p/CZM-nENqZcw/",
  },
  {
    logo: imagePath.GalleryLogo9,
    link: "https://www.instagram.com/p/CY9llVWK8Ny/",
  },
  {
    logo: imagePath.GalleryLogo10,
    link: "https://www.instagram.com/p/CYuUc-mKNxq/",
  },
  {
    logo: imagePath.GalleryLogo11,
    link: "https://www.instagram.com/p/CYjci_IKQdW/",
  },
  {
    logo: imagePath.GalleryLogo12,
    link: "https://www.instagram.com/p/CYWuTo0q-pU/",
  },
  {
    logo: imagePath.GalleryLogo13,
    link: "https://www.instagram.com/p/CYKH_YQqs1J/",
  },
  {
    logo: imagePath.GalleryLogo14,
    link: "https://www.instagram.com/p/CX4GVezKP4J/",
  },
  {
    logo: imagePath.GalleryLogo15,
    link: "https://www.instagram.com/p/CXwDd-jKsHc/",
  },
  {
    logo: imagePath.GalleryLogo16,
    link: "https://www.instagram.com/p/CXg5-h1KJ7a/",
  },
  {
    logo: imagePath.GalleryLogo17,
    link: "https://www.instagram.com/p/CXMSfm5KxNc/",
  },
  {
    logo: imagePath.GalleryLogo18,
    link: "https://www.instagram.com/p/CW6RldcqheP/",
  },
  {
    logo: imagePath.GalleryLogo19,
    link: "https://www.instagram.com/p/CWoG-e1qBGh/",
  },
  {
    logo: imagePath.GalleryLogo20,
    link: "https://www.instagram.com/p/CWYwG26IPeW/",
  },
];

export const Dashboard_Content = {
  MACHINE_HOURS: "MACHINE HOURS",
  Landfill_Equipment_Rebuilder:
    "A global landfill equipment rebuilder that has supplied reconditioned and work ready landfill compactors, earthmoving and construction equipment since 1976.",
  Next: "Next",
  Prev: "Prev",
  Click_here: "Click here for more details and pricing",
};

export const Inventory_Content = {
  Price: "Price",
  Description: "Description",
  MACHINE_HOURS: "MACHINE HOURS",
  ECM_IDLE_HOURS: "ECM IDLE HOURS",
  LOAD_FACTOR: "LOAD FACTOR",
  NEUTRAL_HOURS: "NEUTRAL HOURS",
};

export const Inventory_Details = {
  Model: "CAT 836K (2018)  UNIT# C6783",
  MACHINE_HOURS: "9875",
  ECM_IDLE_HOURS: "2142",
  Price_CAD: " $0.00 CAD",
  Price_USD: " $0.00 USD",
  Description: [
    "WARRANTY INCLUDED",
    "CAT C18 ACERT Engine",
    "Block Heater",
    "Powershift Transmission",
    "Cab (ROPS) with A/C",
    "Pressurized Cab Pre-Cleaner",
    "CAT SU Landfill Blade",
    "CAT Combination Compaction Wheels",
    "Front & Rear No Spin Differentials",
    "Front & Rear Striker Bars",
    "STIC Steering System",
    "Front Wheel Cleaners",
    "Hydraulic Belly Pans",
    "Auto Reverse Cooling Fan",
    "Front Windshield Guard",
    "Rear Rad Guard",
    "Stop & Flood Light Arrangement",
    "Ansul Fire Suppression",
    "Rear View Camera",
    "Air Turbine Engine Pre-Cleaner",
    "Swing Out Stairs",
    "Heated Mirrors",
    "Fully Maintained by CAT from new",
    "Rated at 562 HP",
    "Weight 123,500 LBS",
  ],
  Details: {
    Title: "Repair / Condition Report",
    SubTitle: "The following are repairs completed by the CAT Dealer prior to Marcel Equipment owning the machine;",
    SubTitle2: "This unit had all OEM servicing & repairs completed by CAT dealer from new.",
    SubTitle3: "At 9,057 hours, service letter for transmission/transfer case was completed",
    SubTitle3Point1: "Torque converter was RECONDITIONED",
    SubTitle3Point2: "Transmission was RECONDITIONED",
    SubTitle3Point3: "Transmission oil pump was RECONDITIONED",
    SubTitle3Point4: "Transmission oil cooler REPLACED",
    SubTitle4: "At 9,070 hours, NEW NRS cooler installed",
    SubTitle5: "At 9,588 hours, REMAN DPF filter was installed",
    SubTitle6: "At 9,868 hours, 2,000 hour service was completed",
  },
};

export const Reconditioning_Process = {
  Purchase_Inspection: {
    title: "Pre-Purchase Inspection",
    subTitle: "Background checks and inspections to ensure quality.",
    index: "01",
  },
  Initial_Preparation: {
    title: "Initial Preparation",
    subTitle: "Power washing and removal of panels and covers.",
    index: "02",
  },
  Thorough_Inspection: {
    title: "Thorough Inspection",
    subTitle: "Comprehensive checks by our shop foreman and mechanics.",
    index: "03",
  },
  Operational_Testing: {
    title: "Operational Testing",
    subTitle: "Complete performance checks on all systems.",
    index: "04",
  },
  Servicing_Repairs: {
    title: "Servicing and Repairs",
    subTitle: "Addressing all issues identified during the inspection.",
    index: "05",
  },
  Cosmetic_Repairs: {
    title: "Cosmetic Repairs",
    subTitle: "Welding, sandblasting, priming, and painting.",
    index: "06",
  },
  Final_Inspection: {
    title: "Final Inspection",
    subTitle: "Rigorous pre-delivery inspection (PDI).",
    index: "07",
  },
  Marcel_Equipment: {
    title: "Marcel Equipment Rebuilds & Reconditioning",
    subTitle:
      "Our experienced technicians provide a thorough overhaul of your equipment, ensuring every component meets our exacting standards.",
    index: "01",
  },
  Certified_Rebuild: {
    title: "Cat Certified Rebuild Machine Components (CMCR)",
    subTitle:
      "In partnership with our local Cat dealer, we offer extended warranties on Cat components. Simultaneously, Marcel Equipment handles the reconditioning of non-powertrain elements.",
    index: "02",
  },
  Provide_Extended: {
    title: "Cat Certified Powertrain Rebuilds (CPT)",
    subTitle:
      "Working with our local Cat dealer, we provide extended warranties on powertrain components. Marcel Equipment complements this by reconditioning the remaining non-powertrain components.",
    index: "03",
  },

  Our_process_ensures:
    "Our process ensures each machine is in top operating condition, offering you the reliability of new equipment at a fraction of the cost.",

  Years_Experience: {
    title: "48+ Years of Experience",
    subTitle: "Specializing in rebuilding Landfill Compactors and Waste Handling Dozers.",
  },
  Expert_Team: {
    title: "Expert Team",
    subTitle: "Our skilled tradespeople are adept at identifying & addressing problematic areas.",
  },
  Quality_Commitment: {
    title: "Quality Commitment",
    subTitle: "Each employee takes pride in the product we sell.",
  },
  Thorough_Process: {
    title: "Thorough Process",
    subTitle: "Our comprehensive rebuilding covers all major components.",
  },
  Expert_Team: {
    title: "Cat Certified Options",
    subTitle: "We offer Cat Certified rebuilds with extended warranties for added assurance.",
  },
  Quality_Commitment: {
    title: "Reputable Industry Leader",
    subTitle: "Trusted by dealers and end-users, we have a strong reputation backed by references.",
  },
  Transparency: {
    title: "Transparency",
    subTitle: "We provide clear information about our processes and products.",
  },
  Customer_Approach: {
    title: "Customer-Centric_Approach",
    subTitle: "Fair and easy to do business with, we prioritize your needs.",
  },
  Exceptional_Communication: {
    title: "Exceptional Communication",
    subTitle: "We ensure you understand every detail of your purchase.",
  },
  Proud_Our_Work: {
    title: "Proud of Our Work",
    subTitle: "We stand behind every machine that leaves our facility with the Marcel Equipment name.",
  },
  Exceptional_Communication: {
    title: "Exceptional Communication",
    subTitle: "We ensure you understand every detail of your purchase.",
  },
};

export const Our_Team = {
  Sales_Rentals: {
    title: "Sales and Rentals",
    subTitle:
      "Our knowledgeable sales team can help you find the right equipment for purchase or rental, ensuring you have the tools necessary for your job.",
    index: SalesRentals,
  },
  Technical_Support: {
    title: "Technical Support",
    subTitle:
      "Our skilled technicians are available to assist with equipment maintenance and repairs, ensuring your machinery operates at peak performance.",
    index: TechnicalSupport,
  },
  Customer_Service: {
    title: "Customer Service",
    subTitle:
      "We pride ourselves on our customer-first approach, always ready to answer your questions and provide guidance.",
    index: CustomerService,
  },
};

export const Team_Members = [
  {
    title: "Mark Frederick",
    subTitle: "General Manager",
    about_1:
      "Mark has over 25 years of experience in the heavy equipment industry. He was always passionate about heavy equipment, right from a toddler who began with Tonka trucks in the sandbox, to fueling and greasing machines when he was old enough to work for his relatives in the construction industry and eventually operating equipment. ",
    about_2:
      "After going to University for his BBA, he knew a job in the financial and conventional business environment was not where his passion was. His passion was to be in the heavy equipment industry.",
    linkedin: "https://www.linkedin.com/in/mark-frederick-757b4134/",
    email: "mark@marcelequipment.com",
    avtar: MarkFrederick,

    fullBio: (
      <p>
        Mark has over 25 years of experience in the heavy equipment industry. He was always passionate about heavy
        equipment, right from a toddler who began with Tonka trucks in the sandbox, to fueling and greasing machines
        when he was old enough to work for his relatives in the construction industry and eventually operating
        equipment.
        <br />
        <br />
        After going to University for his BBA, he knew a job in the financial and conventional business environment was
        not where his passion was. His passion was to be in the heavy equipment industry. As a result, he made the
        decision to go to college to become a heavy equipment technician. After college, he accepted a job with the
        local Cat dealer where he managed to skip getting his hands dirty and went into their Management Trainee
        program. Mark spent close to 15 years working at the Cat dealership through various jobs on the product support
        side of the business.
        <br />
        <br />
        In 2014, Marcel Equipment approached him with an opportunity to join the company. It was a tough decision to
        leave a company that provided him with many great opportunities and one that he thought he would retire from.
        However, he already had a close relationship with Marcel Equipment and the move made sense for him and his
        family, and he joined the team. He believes in doing things right the first time; as well as being fair and
        honest, which is a perfect fit for the trustworthy reputation of Marcel Equipment. He works by the motto…would
        you feel comfortable selling this piece of equipment to your own father?
        <br />
        <br />
        Today, he manages the business at Marcel Equipment for Groupe Poisson and loves coming to work each day to face
        the rewards and challenges of the industry.
        <br />
        <br />
        Mark and his wife Tiffany have two young children. His hobbies include playing hockey, fishing, and
        snowmobiling.
        <br />
        <br />
        Mark looks forward to continuing the tradition of providing quality used equipment for Marcel Equipment and
        building upon the Marcel Equipment reputation and name that has been built over the past 45 years.
      </p>
    ),
  },
  {
    title: "John Stewart",
    subTitle: "Sales Manager",
    about_1:
      "John has 50 years of experience in the heavy equipment Sales and Servicing industry. He is a licensed Heavy Equipment Technician by trade and uses that experience everyday sourcing and selling equipment for Marcel Equipment Limited.",
    about_2:
      "He has been the Sales Manager of Marcel Equipment Ltd. for over 25 years and has a great knowledge of all types of construction equipment, especially the Landfill Compactors and Waste Handler Crawler Tractors that Marcel Equipment specializes in.",
    linkedin: "https://www.linkedin.com/in/john-stewart-01444990/",
    email: "john@marcelequipment.com",
    avtar: JohnStewart,

    fullBio: (
      <p>
        John has 50 years of experience in the heavy equipment Sales and Servicing industry. He is a licensed Heavy
        Equipment Technician by trade and uses that experience everyday sourcing and selling equipment for Marcel
        Equipment Limited.
        <br />
        <br />
        He is an old farm boy who grew up operating and fixing all types of farm and construction equipment. In his off
        time, he enjoys fishing, landscaping, remodeling properties and beekeeping, which he says teaches him patience.
        <br />
        <br />
        John has three grown sons & several grandchildren to enjoy.
        <br />
        <br />
        John welcomes your calls and inquiries, be it to discuss an application, what type of equipment to specify in
        your bid documents, or to purchase a machine. In a complex industry with many options, let him help you in
        determining the best possible equipment to complete the work most effectively.
      </p>
    ),
  },
  {
    title: "Manish Chopra",
    subTitle: "Controller",
    about_1:
      "Manish has built a distinguished career spanning nearly two decades in Financial Management, Consulting, Accounting, Finance and Project Management. At Marcel, he leverages this extensive experience to craft and execute strategies that create meaningful value for both customers and the company.",
    about_2:
      "A transformative leader, Manish has consistently driven growth and value creation for customers and employees throughout his career.",
    linkedin: "https://www.linkedin.com/in/manish-chopra-95710b64/",
    email: "manish@marcelequipment.com",
    avtar: ManishChopra,

    fullBio: (
      <p>
        Manish has built a distinguished career spanning nearly two decades in Financial Management, Consulting,
        Accounting, Finance and Project Management. At Marcel, he leverages this extensive experience to craft and
        execute strategies that create meaningful value for both customers and the company.
        <br />
        <br />
        A transformative leader, Manish has consistently driven growth and value creation for customers and employees
        throughout his career.
        <br />
        <br />
        Originally from India, Manish holds a Master’s in Business Administration (MBA). Now based in London, Ontario,
        with his wife, son, and daughter, he brings a unique blend of global expertise and a deep understanding of the
        Banking, Financial Services & heavy equipment industry. His leadership aligns seamlessly with Marcel’s vision,
        ensuring customer needs are transformed into innovative solutions that enable dealerships to grow and thrive.
        <br />
        <br />
        <u>Manish’s Vision for Marcel</u>
        <br />
        Each year presents both challenges and opportunities at Marcel. Despite the obstacles, there is much to
        celebrate. On the product front, the team continues to innovate, developing new modules that promise to drive
        growth for years to come. At the same time, we are reengineering our approach to product management and release
        alignment—a foundational initiative that strengthens our management capabilities and ensures long-term success.
        <br />
        <br />
        Internally, I am confident these efforts will lead to a series of self-sustaining achievements for the business.
        <br />
        <br />
        On the Sales and Marketing side, our teams are well-positioned to accelerate customer acquisition. I am excited
        to implement an actionable growth strategy that provides clear direction and prioritization, enabling all teams
        to achieve sustained success. Together, these initiatives position Marcel Equipment as a leader in building a
        more sustainable future for the heavy equipment industry
      </p>
    ),
  },
  {
    title: "Andrea Stone",
    subTitle: "Administrative Officer",
    about_1:
      "Andrea joined Marcel Equipment Limited 6 years ago in the role of Administrative Officer. Raised in London, Andrea finished her college studies in Business Administration and headed out West to work for a ski resort in the winter, and a golf course in the summer in beautiful Kananaskis Alberta running the front offices.",
    about_2:
      "Family drew Andrea back to London, and she was very excited to join Marcel Equipment learning about the heavy equipment industry. She runs the marketing/advertising for Marcel Equipment as well as running the front desk and helping with accounting. She very much enjoys the people she works with and learning about the machines is fascinating.",
    linkedin: "https://www.linkedin.com/in/andrea-stone-88229b207/",
    email: "andrea@marcelequipment.com",
    avtar: AndreaStone,

    fullBio: (
      <p>
        Andrea joined Marcel Equipment Limited 6 years ago in the role of Administrative Officer. Raised in London,
        Andrea finished her college studies in Business Administration and headed out West to work for a ski resort in
        the winter, and a golf course in the summer in beautiful Kananaskis Alberta running the front offices.
        <br />
        <br />
        Family drew Andrea back to London, and she was very excited to join Marcel Equipment learning about the heavy
        equipment industry. She runs the marketing/advertising for Marcel Equipment as well as running the front desk
        and helping with accounting. She very much enjoys the people she works with and learning about the machines is
        fascinating.
        <br />
        <br />
        Outside of work, Andrea enjoys singing all different kinds of music, attending concerts and theatre, and is a
        big baseball, football, and hockey fan!
        <br />
        <br />
        Andrea looks forward to being your first point of contact with Marcel Equipment and is happy to direct your
        call.
      </p>
    ),
  },
  {
    title: "PAUL PRINCE",
    subTitle: "SHOP LEAD HAND",
    about_1:
      "Paul was born and raised in London, ON and has been with Marcel Equipment for 14 years. Paul started out his career as a car mechanic until his father showed him a job posting for heavy machinery work in the local newspaper. He did his schooling and has never looked back. His favorite thing about working at MEL are the challenges and problem solving that differs every day working on all different machines.",
    about_2:
      "Outside of work, Paul enjoys tobogganing with his wife & young son. He also loves to travel to Mexico to the warm beaches and get some relaxing in. Fun fact about Paul, he is oddly athletic but doesn’t play any sports. Paul is an invaluable and respected asset to our team with his wealth of knowledge",
    email: "paulp@marcelequipment.com",
    avtar: PAULPRINCE,
    noReadMore: true,
  },
  ,
  {
    title: "LORANZO KIMPE",
    subTitle: "SHOP LEAD HAND/TECHNICAL ADVISOR",
    about_1:
      "Loranzo was born in Strathroy, ON & has been in London for the past 30 years. He has been with Marcel Equipment for 14 years. Loranzo worked for 18 years at Caterpillar and therefore came with a wealth of knowledge when it comes to heavy machinery. Especially growing up around the machines, his Dad owned 5 D8’s! His favorite thing about working at MEL is problem solving the machines & the people he works with. ",
    about_2:
      "Outside of work, Loranzo enjoys riding his Harley, eating his wife’s homemade meals & camping. Loranzo is a respected leader to our team and we are lucky to have him.",
    email: "paulp@marcelequipment.com",
    avtar: LORANZOKIMPE,
    noReadMore: true,
  },
];

export const What_Sets_Apart = {
  Rigorous_Reconditioning: {
    title: "Rigorous Reconditioning Process",
    subTitle:
      "From inspections to final performance tests, every machine undergoes meticulous care to meet our high standards.",
    index: RigorousReconditioning,
  },
  Centric_Philosophy: {
    title: "Customer-Centric Philosophy",
    subTitle:
      "We believe in transparent communication and building long-term relationships by delivering what we promise.",
    index: CentricPhilosophy,
  },
  Global_Expertise: {
    title: "Global Expertise",
    subTitle:
      "Whether you’re operating in Ontario or overseas, our equipment and support services are designed to help you succeed.",
    index: GlobalExpertise,
  },
};

export const Our_Mission = {
  Quality: {
    title: "Quality",
    subTitle: "Never compromised",
    index: Quality,
  },
  Transparency: {
    title: "Transparency",
    subTitle: "Clear and honest communication at every stage.",
    index: Transparency,
  },
  Customer_Focus: {
    title: "Customer Focus",
    subTitle: "Your success drives our commitment.",
    index: CustomerFocus,
  },
};

// export const Reconditioning_Process = {
//   Purchase_Inspection: {
//     title: "Pre-Purchase Inspection",
//     subTitle: "Background checks and inspections to ensure quality.",
//     index: "01",
//   },
// };

export const Contact_US_Facility = {
  New_Address: {
    title: "New Address",
    subTitle: "11503 Wonderland Road S, St. Thomas, ON N5P 3T1",
    index: MapPin,
  },
  Size: {
    title: "Size",
    subTitle: "38,000 square feet on 15 acres of property",
    index: Size,
  },

  State_Facilities: {
    title: "State-of-the-Art Facilities",
    subTitle:
      "Purpose-built to support our Landfill Compactor Rebuilding / Construction Equipment reconditioning process and provide top-notch service for your equipment needs.",
    index: "01",
  },

  Expanded_Facilities: {
    title: "Expanded Inventory Selection",
    subTitle: "Offering a broader range of high-quality machinery to meet your unique project requirements.",
    index: "02",
  },

  Room_Growth: {
    title: "Room for Growth",
    subTitle: "Designed with flexibility in mind to accommodate evolving customer needs and industry advancements.",
    index: "03",
  },
};

export const OurTeamMechanicInfo = [
  {
    title: "CHUCK DIETRICH",
    subTitle: "TECHNICIAN",
    about_1:
      "Chuck was born in St Thomas, ON & raised in Dutton & Wallacetown, ON & has been with MEL for 4 years. Chuck started out his career working on trucks & has his truck license. He wanted to add a bit of variety to his days and heavy machinery was a great option. Chuck loves working on & learning about all the different kinds of machines MEL brings in. Outside of work he loves to hunt & fish & also go camping with his family. He would love to travel to Europe on day & visit WW2 airfields & family in Holland to eat  his Grandma’s Nasi dish she makes. Fun fact, his mom’s parents & 2 of her siblings all live in the same small town on the same street!  ",
    email: "CHUCKD@MARCELEQUIPMENT.COM",
    avtar: CHUCK_DIETRICH,
  },
  {
    title: "AUSTIN COOK",
    subTitle: "TECHNICIAN",
    about_1:
      "Austin was born and raised in a little town called Springfield, ON. He has been with MEL for 1 year & looks forward to a long career working on heavy equipment. He is a lifelong learner of mechanics having worked on trucks, quads & dirt bikes from a young age. Austin’s favorite things about working at MEL is the people & the variety of work that differs every day. Outside of work, he enjoys hunting and riding dirt bikes. Austin looks forward to visiting Banff & exploring the mountains.",
    email: "AUSTINC@MARCELEQUIPMENT.COM",
    avtar: AUSTIN_COOK,
  },
  {
    title: "ERIC MCLEAN",
    subTitle: "TECHNICIAN",
    about_1:
      "Eric was born in Edmonton, AB & raised in Cobourg, ON. After a move towards London, he has been with MEL for 2.5 years. Eric grew up working on snowmobiles & quads & knew he wanted a career working on the bigger machines. His favorite machines to work on are the D8’s & enjoys doing rebuilds & the unique work involved. His favorite thing to do outside of work is hunt birds. Eric looks forward to one day being on a tropical beach eating hamburgers!",
    email: "ERICM@MARCELEQUIPMENT.COM",
    avtar: ERIC_MCLEAN,
  },
  {
    title: "JONATHAN WENINGER",
    subTitle: "TECHNICIAN",
    about_1:
      "Jonathan was born and raised in Aylmer, ON. He has been with MEL for 4 months & looks forward to a long career working on heavy equipment. He grew up working on cars, dirt bikes & quads & decided to try out working on the bigger equipment. Jonahthan’s favorite things about working at MEL are the great advice & friendliness of the people he works with & learning daily. Outside of work, he enjoys snowmobiling, working on his car & riding dirt bikes. Jonathan hopes to one day visit the Snap-On Tools truck! ",
    email: "JONATHANW@MARCELEQUIPMENT.COM",
    avtar: JONATHAN_WENINGER,
  },
  {
    title: "JACOB DAWSON",
    subTitle: "TECHNICIAN",
    about_1:
      "Jacob was born in Hamilton & raised in Windham Center, ON. He has been with MEL for 8 years & has spent a lot of his life working on tractors as he grew up on a hobby farm. Jacob’s favorite thing about working at MEL is component rebuilding. Outside of work, he enjoys working on the farm, raising animals & kids. He can also be found working in his gardens. Jacob hopes to one day explore more of Canada. ",
    email: "JACOBD@MARCELEQUIPMENT.COM",
    avtar: JACOB_DAWSON,
  },
  {
    title: "TYSON KANA",
    subTitle: "TECHNICIAN",
    about_1:
      "Tyson was born in St Thomas, ON & raised in Springfield, ON. He has been with MEL for 6 months & looks forward to a long career working on heavy equipment. He grew up building Jeeps with his Dad from the frame up & also working on dirt bikes & quads. Tyson’s favorite things about working at MEL is the people & the friendly environment where his colleagues are willing to answer any questions. Outside of work, he enjoys working on his truck, fixing dirt bikes & quads, & hanging out with friends. Tyson looks forward to visiting Nassau, Bahamas one day & eat some nachos!",
    email: "TYSONK@MARCELEQUIPMENT.COM",
    avtar: TYSON_KANA,
  },
  {
    title: "JON GENTER",
    subTitle: "TECHNICIAN",
    about_1:
      "Jon was born and raised in Sydney, NS & has been with Marcel Equipment for 9 years. Jon was born into the heavy equipment industry as his father owned a construction company & he grew up around the machines. His favorite thing about working at MEL is completing full rebuilds of machines. Outside of work, Jon enjoys being out on the boat & relaxing. He would love to travel to the Caribbean Islands one day & has a real fondness for pizza!  Fun fact about Jon, he is a civil engineer graduating from Dalhousie University. ",
    email: "johng@marcelequipment.com",
    avtar: JON_GENTER,
  },
  {
    title: "MICHAEL GUSTEN",
    subTitle: "TECHNICIAN",
    about_1:
      "Michael was born and raised in St Thomas, ON and has been with Marcel Equipment for just over a year. Mike grew up fixing cars & that love of mechanics turned into a love for heavy machinery. His favorite thing about working at MEL is that each day is a different opportunity to work on new equipment. Outside of work, Mike enjoys playing soccer and looks forward to travelling in Croatia sometime in the future. Fun fact about Mike, he trains police dogs and has a real love for tacos! ",
    email: "mikeg@mel.com",
    avtar: MICHAEL_GUSTEN,
  },
  {
    title: "BRENT JACKSON",
    subTitle: "TECHNICIAN",
    about_1:
      "Brent was born in Sarnia & was raised & still lives in Wyoming, ON. He has been with Marcel Equipment for 2 years. Brent was attracted to the heavy machinery industry for a solid career where he can use his critical thinking skills. His favorite thing about working at MEL are the people & the big trucks. Outside of work, Brent enjoys farming & playing rugby. Brent would love to travel Europe one day & eat a lot of pizza!",
    email: "brentj@marcelequipment.com",
    avtar: BRENT_JACKSON,
  },
  {
    title: "DOMINIC KISIEL",
    subTitle: "WELDER/FABRICATOR",
    about_1:
      "Dom was born and raised in London, ON & has been with Marcel Equipment for 10 years. He came to us with no prior heavy equipment experience, but instantly developed a love for it. Dom’s favorite thing about working at MEL is that it is hard work but very rewarding. He loves the no pressure working environment. Outside of work, Dom enjoys family life with his 2 young daughters, hunting & racing dirt bikes & mountain bikes. One day he would love to travel to Poland & visit family. Fun fact about Dom, he is an ex pro motor x rider & is contemplating getting back into competition. We will all be cheering you on Dom!",
    email: "domk@marcelequipment.com",
    avtar: DOMINIC_KISIEL,
  },
];

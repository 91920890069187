import { Box } from "@mui/system";

const Map = () => {
  return (
    <>
      <Box
        sx={{
          height: {
            xs: "300px", // For extra small screens (mobile)
            sm: "300px", // For small screens (tablets)
            md: "100%", // For medium screens (laptops)
            lg: "100%", // For large screens (desktops)
          },
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5842.276333065323!2d-81.183799!3d42.933214!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882ef33f491a7d33%3A0x418fbd4d88c6d666!2s1000%20Progress%20Dr%2C%20London%2C%20ON%20N6N%201B8!5e0!3m2!1sen!2sca!4v1674233694589!5m2!1sen!2sca"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </>
  );
};
export default Map;

import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import HeaderV2 from "./Header/HeaderV2";
import FooterV2 from "./Footer/FooterV2";
const DashboardLayout = ({ children, activeTab, subHeaderCustomerContainer, selectPage }) => {
  return (
    <>
      <HeaderV2 activeTab={activeTab} subHeaderCustomerContainer={subHeaderCustomerContainer} selectPage={selectPage} />
      {children}
      <FooterV2 />
    </>
  );
};
export default DashboardLayout;

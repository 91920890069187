import { Box, Button, Grid, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import HomepageBanner from "../../assets/img/images/reconditioning-page-banner.jpg";
import { useEffect, useRef, useState } from "react";
import {
  DashboardLayoutContent,
  Reconditioning_Process,
  Reconditioning_Process_third_content,
} from "../../Utils/Constants";
import DetailedPictures from "../../assets/img/svg/DetailedPictures.svg";
import CatUndercarriage from "../../assets/img/svg/CatUndercarriage.svg";
import Complete from "../../assets/img/svg/Complete.svg";
import Operational from "../../assets/img/svg/Operational.svg";
import Visual from "../../assets/img/svg/Visual.svg";
import Reconditioning from "../../assets/img/images/Reconditioning-Page.png";
import ReconditioningOption from "./ReconditioningOption/ReconditioningOption";
import ContactUSIcon from "../../assets/img/svg/Contact_US.svg";
import ReconditioningPageIMG from "../../assets/img/images/Reconditioning-Page-Image.png";
import { Path } from "../../Utils/ScreenPath";
import { useNavigate } from "react-router-dom";

const Process_Card = ({ item }) => {
  return (
    <Box className="Process_Card_Box">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box className="Process_Card_Title">{item.title}</Box>
        {item.index ? <Box className="Process_Card_Index">{item.index}</Box> : null}
      </Box>
      <Box className="Process_Card_Sub_Title">{item.subTitle}</Box>
    </Box>
  );
};

const ReconditioningProcessComponentV2 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const absoluteBoxRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(0);
  useEffect(() => {
    if (absoluteBoxRef.current) {
      setBoxHeight(absoluteBoxRef.current.offsetHeight);
    }
  }, [absoluteBoxRef.current]);
  return (
    <>
      <Box>
        <Box
          className={"dashboard_banner"}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${HomepageBanner}) center/cover no-repeat`,
          }}
        >
          <Box className="container">
            <Box
              textAlign={"center"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              className="banner_component"
            >
              <Box className="home_bannar_text">
                Your Trusted Dealer for Reconditioned
                <br /> Landfill and Construction Equipment
              </Box>
              <Box className="home_bannar_sub_text">
                More Than Just a Used Equipment Dealer:
                <br /> Your Source for Fully Reconditioned Heavy Machinery
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="container">
          <Box position={"relative"}>
            <Box
              bottom={"100px"}
              position={"absolute"}
              style={{ background: "#FACD40" }}
              textAlign={"center"}
              ref={absoluteBoxRef}
              className="home_banner_box"
            >
              <Box className="explore_title" mb={"40px"}>
                {Reconditioning_Process_third_content.Slogan}
              </Box>
              <Box className="explore_sub_title" mb={"40px"}>
                {Reconditioning_Process_third_content.Slogan_2}
              </Box>
            </Box>
          </Box>

          <Box marginY={"100px"} position={"relative"} marginTop={`${absoluteBoxRef?.current?.offsetHeight}px`}>
            <Box className="explore_title" textAlign={"center"}>
              Our Commitment to Transparency and Quality
            </Box>
            <Box className="explore_sub_title" mt={"30px"} mb={"30px"} textAlign={"center"}>
              As both a dealer and a reconditioning/rebuilding specialist, we pride ourselves on full transparency.{" "}
              <br />
              Each machine we sell comes with the following:
            </Box>
            <Box className="quality_grid">
              <Grid container item spacing={4}>
                <Grid item xs={12} sm={12} md={4} lg={2.4}>
                  <Box className="quality_box">
                    <Box className="quality_img">
                      <img src={Visual} alt="" />
                    </Box>
                    <Box className="quality_title">Visual Inspection Results</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2.4}>
                  <Box className="quality_box">
                    <Box className="quality_img">
                      <img src={Operational} alt="" />
                    </Box>
                    <Box className="quality_title">Operational and pressure testing results</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2.4}>
                  <Box className="quality_box">
                    <Box className="quality_img">
                      <img src={Complete} alt="" />
                    </Box>
                    <Box className="quality_title">Complete repair history</Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2.4}>
                  <Box className="quality_box">
                    <Box className="quality_img">
                      <img src={CatUndercarriage} alt="" />
                    </Box>
                    <Box className="quality_title">Cat undercarriage inspection reports</Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={2.4}>
                  <Box className="quality_box">
                    <Box className="quality_img">
                      <img src={DetailedPictures} alt="" />
                    </Box>
                    <Box className="quality_title">Detailed pictures and specifications</Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box textAlign={"center"} marginTop={"50px"}>
              <Button
                className="our_team"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(Path?.Category);
                }}
              >
                <span>Explore Our Inventory</span>
              </Button>
            </Box>
          </Box>
        </Box>

        <Box sx={{ background: "#F5F5F5" }}>
          <Box
            className="container"
            sx={{
              paddingY: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            {" "}
            <Box className="explore_title" textAlign={"center"}>
              Marcel Equipment's Reconditioning Process
            </Box>
            <Box marginTop={"30px"}>
              <Grid container item spacing={4} sx={{ marginBottom: "20px" }}>
                {/* Process Card Items (First Row) */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Purchase_Inspection} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Initial_Preparation} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Thorough_Inspection} />
                </Grid>

                {/* Process Card Items (Second Row) */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Operational_Testing} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Servicing_Repairs} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Cosmetic_Repairs} />
                </Grid>

                {/* Final Section (Final Inspection + Box + Image) */}
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    {/* Left Section: Final Inspection & Description */}
                    <Grid item xs={12} md={4}>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Process_Card item={Reconditioning_Process.Final_Inspection} />
                          </Grid>

                          <Grid item xs={12} sm={6} md={12} lg={12}>
                            <Box
                              height={"100%"}
                              bgcolor={"#141215"}
                              display={"flex"}
                              alignItems={"center"}
                              textAlign={"center"}
                            >
                              <Box className="process_ensures_box" textAlign={"center"}>
                                Our process ensures each machine is in top operating condition, offering you the
                                reliability of new equipment at a fraction of the cost.
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      sx={{
                        display: {
                          xs: "none", // For extra small screens (mobile)
                          sm: "none", // For small screens (tablets)
                          md: "block", // For medium screens (laptops)
                          lg: "block", // For large screens (desktops)
                        },
                      }}
                    >
                      <Box
                        className={"dashboard_banner"}
                        sx={{
                          background: `url(${Reconditioning})`,
                          objectFit: "cover",
                          height: "100%",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box sx={{ background: "#ffffff" }}>
          <Box
            className="container"
            sx={{
              paddingY: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            <ReconditioningOption />
          </Box>
        </Box>
        <Box sx={{ background: "#F5F5F5" }}>
          <Box
            className="container"
            sx={{
              paddingY: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            {" "}
            <Box className="explore_title" textAlign={"center"}>
              Why Choose Marcel Equipment for Reconditioning?
            </Box>
            <Box marginTop={"30px"}>
              <Grid container item spacing={4} sx={{ marginBottom: "20px" }}>
                {/* Process Card Items (First Row) */}
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Years_Experience} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Expert_Team} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Quality_Commitment} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Thorough_Process} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Expert_Team} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Quality_Commitment} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Transparency} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Customer_Approach} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Exceptional_Communication} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Proud_Our_Work} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Process_Card item={Reconditioning_Process.Exceptional_Communication} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    sx={{ background: "#141215", height: "100%", cursor: "pointer" }}
                    className="Reconditioning_Process_Contact_Us"
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate(Path.ContactUs);
                    }}
                  >
                    <Box className="Process_Card_Title" sx={{ color: "#FACD40" }}>
                      CONTACT US
                    </Box>
                    <Box display={"flex"} alignItems={"flex-end"}>
                      <img
                        src={ContactUSIcon}
                        alt=""
                        style={{ objectFit: "cover", objectPosition: "center", margin: "30px" }}
                        className="Process_Card_IMG"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <img src={ReconditioningPageIMG} alt="" style={{ width: "-webkit-fill-available" }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box sx={{ background: "#ffffff" }}>
          <Box
            className="container"
            sx={{
              paddingY: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            <Box className="explore_title" textAlign={"center"}>
              Doing it Right Today… to Earn Your Business Tomorrow
            </Box>
            <Box marginTop={"20px"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
              <Button
                className="our_team"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(Path?.Category);
                }}
              >
                <span style={{ minWidth: "150px" }}>Explore Our Inventory</span>
              </Button>
              <Button
                className="about_us"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(Path.AboutUs);
                }}
              >
                <span style={{ width: "150px" }}>ABOUT US</span>
              </Button>
              <Button
                className="contact_us"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  navigate(Path.ContactUs);
                }}
                style={{ cursor: "pointer" }}
              >
                <span style={{ width: "150px" }}>CONTACT US</span>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ReconditioningProcessComponentV2;

import { Helmet } from "react-helmet";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import ContactUsComponentV2 from "../../components/ContactUsComponent/ContactUsComponentV2";
const ContactUs = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-VWGPB9LR1K", // Replace with your Google Tag Manager ID
      dataLayerName: "PageDataLayer",
      dataLayer: {
        page: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <>
      <Helmet>
        <title>Contact Marcel | Construction & Landfill Equipment Experts</title>
        <meta
          name="description"
          content="Reach out to Marcel Equipment on our used, rebuilt, and reconditioned landfill and construction equipment. We're here to assist with all your equipment needs."
        />
      </Helmet>
      <DashboardLayout activeTab={"ContactUs"} subHeaderCustomerContainer={"ContactUs_Container"}>
        {/* <Box className="container">
          <Box className="p-x-20 p-b-50">
            <Box
              className="ContactUs_Container"
              maxWidth={
                localStorage.getItem("language") === "fr" || localStorage.getItem("language") === "es"
                  ? "1028px"
                  : "797px"
              }
            >
              <ContactUsComponent />
            </Box>
          </Box>
        </Box> */}
        <ContactUsComponentV2 />
      </DashboardLayout>
    </>
  );
};
export default ContactUs;

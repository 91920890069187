import { Box, Grid } from "@mui/material";
import { OurTeamMechanicInfo, Team_Members } from "../../../Utils/Constants";
import EmailFill from "../../../assets/img/svg/mail-fill.svg";
import InfoIconFill from "../../../assets/img/svg/info-fill.svg";
import LinkedinFill from "../../../assets/img/svg/linkedin-fill.svg";
import { useEffect, useRef, useState } from "react";

const MembersCard = ({ item }) => {
  const [readmore, setReadmore] = useState(false);
  return (
    <Grid container item spacing={4} mb={"30px"}>
      <Grid item xs={12} sm={12} md={12} lg={3}>
        <img
          src={item.avtar}
          alt="avtar"
          style={{ width: "100%", objectFit: "cover", height: "362px", objectPosition: "top" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={9}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <Box className="Members_Title">{item.title}</Box>
            <Box className="Members_Sub_Title">{item.subTitle}</Box>
          </Box>
          <Box display={"flex"} alignItems={"center"}>
            {item.email && (
              <a href={`mailto:${item.email}`}>
                <Box className="email-fill-icon">
                  <img src={EmailFill} alt="" />
                </Box>
              </a>
            )}
            {item.linkedin && (
              <a href={item.linkedin} target="_blank">
                <Box className="email-fill-icon">
                  <img src={LinkedinFill} alt="" />
                </Box>
              </a>
            )}
          </Box>
        </Box>
        {!readmore ? (
          <>
            <Box className="Members_Dec">{item.about_1}</Box>

            <Box className="Members_Dec">
              {item.about_2}{" "}
              {!item.noReadMore && (
                <h100
                  style={{ cursor: "pointer", color: "#FACD40", textDecoration: "underline" }}
                  onClick={() => {
                    setReadmore(true);
                  }}
                >
                  Read More
                </h100>
              )}
            </Box>
          </>
        ) : (
          <Box className="Members_Dec">
            {item?.fullBio}
            {/* <span
              style={{ cursor: "pointer", color: "#FACD40", textDecoration: "underline" }}
              onClick={() => {
                setReadmore(true);
              }}
            >
              Read Less
            </span> */}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

const OurTeamMembers = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const hoverRef = useRef(null);

  // Close hover-content when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (hoverRef.current && !hoverRef.current.contains(event.target)) {
        setActiveIndex(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Box
        className="Our_Team_Members"
        sx={{
          paddingY: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
        }}
      >
        <Box className="container">
          <Box className="Our_Team_Members_Text" mb={"30px"}>
            Our Team Members
          </Box>

          <Box>
            {Team_Members.map((item) => {
              return <MembersCard item={item} />;
            })}
          </Box>

          <Box
            sx={{
              paddingTop: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            <Grid container item spacing={4} mb={"30px"} justifyContent={"center"}>
              {OurTeamMechanicInfo.map((item, index) => {
                return (
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Box
                        position={"relative"}
                        ref={hoverRef}
                        // sx={{
                        //   "&:hover .hover-content": { opacity: 1, visibility: "visible" },
                        // }}
                      >
                        <img
                          src={item?.avtar}
                          alt=""
                          style={{
                            width: "100%",
                            height: "362px",
                            objectFit: "cover",
                            objectPosition: "top",
                          }}
                        />

                        <Box
                          position={"absolute"}
                          sx={{
                            width: "-webkit-fill-available",
                            height: "-webkit-fill-available",
                            top: 0,
                            background: " linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%)",
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            flexDirection={"column"}
                            height={"-webkit-fill-available"}
                          >
                            <Box display={"flex"} justifyContent={"end"} pr={"20px"} pt={"20px"}>
                              {item.email && (
                                <a href={`mailto:${item.email}`}>
                                  <Box className="email-fill-icon">
                                    <img src={EmailFill} alt="" />
                                  </Box>
                                </a>
                              )}

                              {/* I button */}
                              <Box
                                className="info-fill-icon"
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent event bubbling
                                  setActiveIndex(activeIndex === index ? null : index);
                                }}
                              >
                                <img src={InfoIconFill} alt="" width={"27px"} />
                              </Box>
                            </Box>
                            <Box mb={"15px"}>
                              <Box color={"#FACD40"} className="Our_Team_title" sx={{ textAlign: "center" }}>
                                {item?.title}
                              </Box>
                              <Box color={"#FFFFFF"} className="Our_Team_Banner_Sub_Title" sx={{ textAlign: "center" }}>
                                {item?.subTitle}
                              </Box>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          position={"absolute"}
                          className="hover-content"
                          // sx={{
                          //   width: "-webkit-fill-available",
                          //   height: "-webkit-fill-available",
                          //   top: 0,
                          //   background: " linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%)",
                          //   opacity: 0,
                          //   visibility: "hidden",

                          //   transition: "opacity 0.4s, visibility 0.4s",
                          // }}
                          sx={{
                            width: "100%",
                            height: "100%",
                            top: 0,
                            background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%)",
                            opacity: activeIndex === index ? 1 : 0,
                            visibility: activeIndex === index ? "visible" : "hidden",
                            transition: "opacity 0.4s, visibility 0.4s",
                          }}
                        >
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            flexDirection={"column"}
                            height={"-webkit-fill-available"}
                            sx={{ background: "#141215BF" }}
                            p={"20px 8px 20px 20px"}
                          >
                            <Box
                              className="Our_Team_Banner_Sub_Title"
                              sx={{ textAlign: "start", fontWeight: "400", overflowY: "auto" }}
                              color={"#FFFFFF"}
                            >
                              {item?.about_1}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default OurTeamMembers;

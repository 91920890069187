export const Path = {
  Home: "/",
  AboutUs: "/about-us",
  ContactUs: "/contact-us",
  Category: "/our-inventory",
  OurTeam: "/our-team",
  ReconditioningProcess: "/reconditioning-process",
  Links: "/links",
  Gallery: "/gallery",
  Inventory: "/inventory",
  Equipment: "/equipment",
};

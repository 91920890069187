import { Box, Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Our_Team, Our_Team_second_content } from "../../Utils/Constants";
import OurTeamBanner from "../../assets/img/images/Our-Team-Banner.jpg";
import OurTeamMembers from "./OurTeamMembers/OurTeamMembers";
import { useNavigate } from "react-router-dom";
import { Path } from "../../Utils/ScreenPath";

const Process_Card = ({ item }) => {
  return (
    <Box className="Process_Card_Box">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box className="Process_Card_Title">{item.title}</Box>
        {item.index ? (
          <Box className="Process_Card_Index">
            <img src={item.index} alt="" />
          </Box>
        ) : null}
      </Box>
      <Box className="Process_Card_Sub_Title">{item.subTitle}</Box>
    </Box>
  );
};

const OurTeamComponent = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  return (
    <>
      <Box
        style={{
          // background: `url(${OurTeamBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${OurTeamBanner}) center/cover no-repeat`,
        }}
        className="bg-[image:var(--image-url)]"
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Title">Meet Our Team</Box>
        </Box>
      </Box>

      <Box
        className="Our_Team_Banner_Sub_Box"
        sx={{
          paddingY: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
        }}
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Sub_Title">
            At Marcel Equipment Limited, our team is dedicated to your success and committed to providing exceptional
            service in every aspect of your equipment needs. With decades of experience in the construction and landfill
            industries, our professionals are here to ensure you receive the best solutions tailored to your projects.
          </Box>

          <Box className="Expertise_Text">Our Expertise Includes:</Box>

          <Grid container item spacing={4} sx={{ marginBottom: "20px" }}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Process_Card item={Our_Team.Sales_Rentals} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Process_Card item={Our_Team.Technical_Support} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Process_Card item={Our_Team.Customer_Service} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <OurTeamMembers />

      <Box sx={{ background: "#ffffff" }}>
        <Box
          className="container"
          sx={{
            paddingY: {
              xs: "40px", // For extra small screens (mobile)
              sm: "60px", // For small screens (tablets)
              md: "80px", // For medium screens (laptops)
              lg: "100px", // For large screens (desktops)
            },
            maxWidth: "950px",
          }}
          margin={"auto"}
        >
          <Box className="explore_title" textAlign={"center"}>
            Together, we can achieve your project goals with the right equipment and support.
          </Box>
          <Box marginTop={"20px"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
            <Button
              className="our_team"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path?.Category);
              }}
            >
              <span style={{ minWidth: "150px" }}>Explore Our Inventory</span>
            </Button>
            <Button
              className="about_us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.AboutUs);
              }}
            >
              <span style={{ width: "150px" }}>ABOUT US</span>
            </Button>
            <Button
              className="contact_us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.ContactUs);
              }}
              style={{ cursor: "pointer" }}
            >
              <span style={{ width: "150px" }}>CONTACT US</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default OurTeamComponent;

import { Box, Drawer } from "@mui/material";
import imagePath from "../../../assets/imagePath";
import { Path } from "../../../Utils/ScreenPath";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleTranslate } from "../../../hooks/useGoogleTranslate";
import FacebookIcon from "../../../assets/img/svg/facebook-v2.svg";
import InstagramIcon from "../../../assets/img/svg/instagram-v2.svg";
import LinkedinIcon from "../../../assets/img/svg/linkedin-v2.svg";
import { social_media_link } from "../../../Utils/Constants";
import { useEffect } from "react";
import { getAllMachinesList, postMachinesList } from "../../../services/machineService";
import { ApplicationId } from "../../../api/url";

const NavigationList = ({ activeTab, midScreenMenuBar }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [machineData, setMachineData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await getAllMachinesList();
        const categoryDetails = response?.data.find((item) => item.AssetTypeId === 2)?.CategoryDetails || [];

        const desiredOrder = [38, 40, 39, 23, 44, 36, 35, 41];

        const filteredCategories = [];
        categoryDetails.forEach((item) => {
          const indexInDesiredOrder = desiredOrder.indexOf(item?.CategoryId);
          if (indexInDesiredOrder !== -1) {
            // If the item is found in the desiredOrder, add it to filteredCategories
            filteredCategories[indexInDesiredOrder] = item;
          }
        });

        const sortedCategories = filteredCategories;
        const data = await Promise.all(
          sortedCategories?.map(async (item) => {
            const payload = {
              PageSize: 4,
              PageNumber: 0,
              SortColumn: "SortOrder ASC",
              CategoryId: item.CategoryId,
              ApplicationId: ApplicationId,
            };

            const machineList = await postMachinesList(payload);

            return {
              ...item,
              id: item.CategoryName,
              link: `/${item.CategoryId}-${item.CategoryName.toLowerCase().replaceAll(" ", "-")}`,
              MachineList: machineList?.data || [],
            };
          })
        );

        setMachineData(data);
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <Box
        className={activeTab === "Home" ? "header_navigation_label_active" : "header_navigation_label"}
        onClick={() => {
          navigate(Path.Home);
          localStorage.removeItem("assetType");
        }}
      >
        {t("HeaderNavigationLabel_Home")}
      </Box>

      <Box className="dropdown">
        <Box
          onClick={() => {
            navigate(Path.Category);
            localStorage.removeItem("assetType");
          }}
          className={activeTab === "Category" ? "header_navigation_label_active" : "header_navigation_label"}
        >
          Our Inventory
        </Box>
        <Box className="dropdown-content" sx={{ width: "200px" }}>
          {isLoading ? (
            <div
              className="spinner-container"
              style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
            >
              <div className="loading-Btn-spinner" style={{ width: "30px", height: "30px", margin: "20px 0" }}></div>
            </div>
          ) : (
            <Box sx={{ paddingTop: "20px" }}>
              {machineData?.map((item) => {
                return (
                  <Box
                    className={activeTab === "Gallery" ? "header_navigation_label_active" : "header_navigation_label"}
                    // onClick={() => navigate(Path.Gallery)}
                    padding={"0px 20px 20px 20px"}
                    textAlign={"center"}
                    justifyContent={"center"}
                    onClick={() => {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                      navigate(item?.link);
                    }}
                  >
                    {item?.CategoryName}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>

      <Box className="m-dropdown">
        <Box
          className={activeTab === "Category" ? "header_navigation_label_active" : "header_navigation_label"}
          onClick={() => {
            navigate(Path.Category);
            localStorage.removeItem("assetType");
          }}
          justifyContent={"center"}
        >
          Our Inventory
        </Box>
      </Box>

      <Box
        className={activeTab === "ReconditioningProcess" ? "header_navigation_label_active" : "header_navigation_label"}
        onClick={() => navigate(Path.ReconditioningProcess)}
        // onClick={() => navigate("/")}
      >
        {t("HeaderNavigationLabel_Reconditioning_Process")}
      </Box>

      <Box
        className={activeTab === "OurTeam" ? "header_navigation_label_active" : "header_navigation_label"}
        onClick={() => navigate(Path.OurTeam)}
        // onClick={() => navigate("/")}
      >
        {t("HeaderNavigationLabel_Our_Team")}
      </Box>

      <Box className="m-dropdown">
        <Box
          className={activeTab === "AboutUs" ? "header_navigation_label_active" : "header_navigation_label"}
          onClick={() => navigate(Path.AboutUs)}
          justifyContent={"center"}
          // onClick={() => navigate("/")}
        >
          {t("HeaderNavigationLabel_About_Us")}
        </Box>
        <Box
          className={activeTab === "Gallery" ? "header_navigation_label_active" : "header_navigation_label"}
          // onClick={() => navigate(Path.Gallery)}
          // onClick={() => navigate("/")}
          justifyContent={"center"}
        >
          {t("HeaderNavigationLabel_Gallery")}
        </Box>
        <Box
          className={activeTab === "Links" ? "header_navigation_label_active" : "header_navigation_label"}
          // onClick={() => navigate("/")}
          justifyContent={"center"}
        >
          {t("HeaderNavigationLabel_Links")}
        </Box>
      </Box>

      <Box className="dropdown">
        <Box
          onClick={() => navigate(Path.AboutUs)}
          className={activeTab === "AboutUs" ? "header_navigation_label_active" : "header_navigation_label"}
        >
          {t("HeaderNavigationLabel_About_Us")}
        </Box>
        <Box className="dropdown-content">
          <Box
            className={activeTab === "Gallery" ? "header_navigation_label_active" : "header_navigation_label"}
            // onClick={() => navigate(Path.Gallery)}
            // onClick={() => navigate("/")}
            padding={"20px 20px 0px 20px"}
            textAlign={"center"}
            justifyContent={"center"}
          >
            {t("HeaderNavigationLabel_Gallery")}
          </Box>
          <Box
            className={activeTab === "Links" ? "header_navigation_label_active" : "header_navigation_label"}
            // onClick={() => navigate("/")}
            padding={"20px 20px 20px 20px"}
            textAlign={"center"}
            justifyContent={"center"}
          >
            {t("HeaderNavigationLabel_Links")}
          </Box>
        </Box>
      </Box>

      <Box
        className={activeTab === "ContactUs" ? "header_navigation_label_active" : "header_navigation_label"}
        onClick={() => navigate(Path.ContactUs)}
        mr={midScreenMenuBar ? 0 : "20px"}
        mb={midScreenMenuBar ? "20px" : "0px"}
      >
        {t("HeaderNavigationLabel_Contact_Us")}
      </Box>
    </>
  );
};

const HeaderV2 = ({ activeTab, subHeaderCustomerContainer, selectPage }) => {
  const [midScreenMenuBar, setMidScreenMenuBar] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleMidScreenMenuClose = () => {
    setMidScreenMenuBar(false);
  };

  const handleMidScreenMenuOpen = () => {
    setMidScreenMenuBar(true);
  };
  return (
    <>
      <Box className="header_v2_bg">
        <Box className="container">
          <Box className="header_v2_top_container">
            <Box display={"flex"}>
              <Box mr={"5px"}>
                <a href={social_media_link.Facebook} target="_blank" rel="noreferrer">
                  <img src={FacebookIcon} alt="" />
                </a>
              </Box>
              <Box mr={"5px"}>
                <a href={social_media_link.Instagram} target="_blank" rel="noreferrer">
                  <img src={InstagramIcon} alt="" />
                </a>
              </Box>
              <Box mr={"5px"}>
                <a href={social_media_link.Linkedin} target="_blank" rel="noreferrer">
                  <img src={LinkedinIcon} alt="" />
                </a>
              </Box>
            </Box>
            <Box display={"flex"}>
              <a href={`tel:519-686-1123`} style={{ textDecoration: "none" }}>
                <Box className="header_v2_title">{t("Phone")}</Box>
              </a>
              <Box className="header_v2_title" marginX={"5px"}>
                |
              </Box>

              <a href={`tel:800-265-5747`} style={{ textDecoration: "none" }}>
                <Box className="header_v2_title">{t("Toll_Free")}</Box>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="logo_header_v2_bg">
        <Box className="container">
          <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
            <Box
              onClick={() => {
                navigate(`/`);
                localStorage.removeItem("assetType");
              }}
            >
              <img src={imagePath.Logo} alt="" style={{ cursor: "pointer" }} className="logo_header_v2_logo" />
            </Box>
            <Box>
              <Box className="header_navigation_box_lg_screen">
                <Box className={`${subHeaderCustomerContainer} d-flex`}>
                  <NavigationList activeTab={activeTab} />
                  <GoogleTranslate />
                </Box>
              </Box>

              <Box className="header_navigation_box_md_screen">
                <MenuIcon className="header_navigation_List_Menu_btn" onClick={handleMidScreenMenuOpen} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Drawer anchor={"left"} open={midScreenMenuBar} onClose={handleMidScreenMenuClose}>
        <Box
          sx={{ width: "100vw" }}
          role="presentation"
          onClick={handleMidScreenMenuClose}
          onKeyDown={handleMidScreenMenuClose}
        >
          <Box className="container">
            <Box className="header_navigation_menu_close">
              <CloseIcon className="header_navigation_List_Menu_btn" onClick={handleMidScreenMenuOpen} />
            </Box>
            <Box className="header_navigation_list_box_md_screen">
              <NavigationList activeTab={activeTab} midScreenMenuBar={midScreenMenuBar} />
              <GoogleTranslate />
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default HeaderV2;

import { Box, Button, Grid } from "@mui/material";
import ContactUsBanner from "../../assets/img/images/Hero_Section.jpg";
import Map from "./Map/Map";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ApplicationId } from "../../api/url";
import { toast } from "react-toastify";
import { postContactUs } from "../../services/contactUsService";
import { Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { Contact_US_Facility, ReCAPTCHA_sitekey, Reconditioning_Process } from "../../Utils/Constants";
import FacilityOne from "../../assets/img/images/facility/facility-1.jpg";
import FacilityTwo from "../../assets/img/images/facility/facility-2.jpg";
import FacilityThree from "../../assets/img/images/facility/facility-3.jpg";
import FacilityFour from "../../assets/img/images/facility/facility-4.jpg";
import ThumbnailImage from "../../assets/img/images/facility/facility.png";
import PlayIcon from "../../assets/img/svg/play-circle.svg";
import FacilityVideo from "../../assets/img/video/facility.mp4";
import { PlayArrow } from "@mui/icons-material";
import { Path } from "../../Utils/ScreenPath";

const Process_Card = ({ item }) => {
  return (
    <Box className="Process_Card_Box">
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box className="Process_Card_Title">{item.title}</Box>
        {item.index ? (
          <Box className="Process_Card_Index">
            <img src={item.index} alt="" />
          </Box>
        ) : null}
      </Box>
      <Box className="Process_Card_Sub_Title">{item.subTitle}</Box>
    </Box>
  );
};

const Card = ({ item }) => {
  return (
    <Box className="Reconditioning_Option_Card">
      <Box display={"flex"}>
        <Box className="Process_Card_Index">{item.index}</Box>
      </Box>
      <Box className="Process_Card_Title" paddingLeft={"0px"}>
        {item.title}
      </Box>
      <Box className="Process_Card_Sub_Title" paddingLeft={"0px"}>
        {item.subTitle}
      </Box>
    </Box>
  );
};

const ContactUsComponentV2 = () => {
  const { state } = useLocation();
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const reCaptchaRef = useRef(null);
  const { t } = useTranslation();

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play().catch((error) => {
          console.error("Autoplay failed:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  };

  const AccSchema = Yup.object().shape({
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    email: Yup.string().email("Enter Valid Email Address").required("Please Enter E-mail Address"),
    message: Yup.string().required("Please Enter message"),
    phone: Yup.string().required("Please Enter Phone Number"),
    reCaptcha: Yup.string().required("recaptcha is a required field"),
  });

  const handleSubmitForm = (value, resetForm) => {
    const htmlForm = `
  <div style="font-size:25px">
    <div>
      <table>
        <tr style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""}</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""}</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
            value?.email ?? ""
          }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""}</a></td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""}</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""}</td>
        </tr>
        <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
          <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Message</td>
          <td style="font-size:16px;color:#86848e;font-weight:bold;padding-right:40px;max-width: 2600px;line-break: anywhere;">${
            value?.message ?? ""
          }</td>
        </tr>
      </table>
    <div>
  </div>
`;
    const htmlFormWidthInventory = `
    <div style="font-size:25px">
      <div>
        <table>
          <tr style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Name</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.firstname ?? ""}</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Last</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.lastname ?? ""}</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Email</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;"><a href = "mailto:${
              value?.email ?? ""
            }" style="color:#551A8B;text-decoration: underline;">${value?.email ?? ""}</a></td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Phone</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.phone ?? ""}</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 0px 0px;color:#000;">Inventory</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.inventory ?? ""}</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 0px;color:#000;">Location</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;">${value?.location ?? ""}</td>
          </tr>
          <tr  style="border-top:1px solid #C8C8C8;border-bottom:1px solid #C8C8C8">
            <td style="font-weight:bold; font-size:16px;padding:10px 40px 10px 40px;color:#000;">Message</td>
            <td style="font-size:16px;color:#86848e;font-weight:bold;max-width: 2600px;line-break: anywhere;">${
              value?.message ?? ""
            }</td>
          </tr>
        </table>
      <div>
    </div>
  `;
    setIsLoading(true);
    let payload = {
      HtmlContent: state ? htmlFormWidthInventory : htmlForm,
      ApplicationId: ApplicationId,
    };
    postContactUs(payload)
      .then((res) => {
        reCaptchaRef?.current?.reset();
        setIsLoading(false);
        toast.success("email send successfully");
        resetForm();
      })
      .catch((err) => {
        toast.error(err?.data);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Box
        style={{
          // background: `url(${ContactUsBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${ContactUsBanner}) center/cover no-repeat`,
        }}
        className="bg-[image:var(--image-url)]"
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Title">
            Contact Us <br />
            <span className="Our_Team_Banner_Sub_Title" style={{ fontWeight: "bold", textTransform: "none" }}>
              Doing it Right Today… To Earn Your Business Tomorrow
            </span>
          </Box>
        </Box>
      </Box>

      <Box
        className="Our_Team_Banner_Sub_Box"
        sx={{
          paddingTop: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
        }}
        bgcolor={"#fff"}
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Sub_Title">
            Are you looking for reliable equipment for your next construction project or landfill operation? At Marcel
            Equipment Limited, we specialize in providing top-quality machinery tailored to meet your specific needs.
          </Box>
          <br />
          <Box className="Our_Team_Banner_Sub_Title">
            We are here to assist you in sourcing the right equipment to ensure the success of your project. Whether you
            require compactors, dozers, loaders, or any other heavy machinery, our team is ready to help.
          </Box>

          <Box
            sx={{
              paddingY: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            <Grid container item spacing={4}>
              <Grid item xs={12} md={12} lg={6}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      flex: "1 1 auto", // Fills remaining space
                      overflow: "hidden", // Ensures content doesn't overflow
                    }}
                  >
                    <Map />
                  </Box>
                  <Box mt={"18px"} bgcolor={"#141215"} padding={"30px"}>
                    <Box className="contact-title">Additional Ways to Connect</Box>

                    <Grid container item mt={"20px"}>
                      <Grid item xs={12} md={12} lg={5}>
                        <Box className="contact-phone">
                          Phone or Text:{" "}
                          <a href="tel:519-686-1123" style={{ textDecoration: "none", color: "#fff" }}>
                            519-686-1123
                          </a>
                        </Box>
                        <Box className="contact-phone">
                          Fax:{" "}
                          <a href="tel:519-686-9350" style={{ textDecoration: "none", color: "#fff" }}>
                            519-686-9350
                          </a>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={12} lg={7}>
                        <Box className="contact-phone">
                          Toll Free:{" "}
                          <a href="tel:800-265-5747" style={{ textDecoration: "none", color: "#fff" }}>
                            800-265-5747
                          </a>
                        </Box>
                        <Box className="contact-phone">
                          Email:{" "}
                          <a href="mailto:sales@marcelequipment.com" style={{ textDecoration: "none", color: "#fff" }}>
                            sales@marcelequipment.com
                          </a>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Formik
                  initialValues={{
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    location: "",
                    message: "",
                    inventory: decodeURIComponent(state),
                    reCaptcha: "",
                  }}
                  validationSchema={AccSchema}
                  onSubmit={(values, { resetForm }) => {
                    handleSubmitForm(values, resetForm);
                  }}
                >
                  {({ errors, touched, values, handleSubmit, handleChange, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid item container spacing={2} sx={{ mt: "10px" }}>
                        <Grid item sx={12} sm={12} lg={12} className="get-in-touch-text">
                          Get in Touch!
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <div className="Contact_Page_field">
                            <input
                              type="text"
                              id="userfirstname"
                              name="firstname"
                              className="contact_page_input"
                              placeholder="First Name*"
                              value={values.firstname}
                              onChange={(e) => handleChange(e)}
                            />

                            {errors.firstname && touched.firstname && (
                              <div style={{ fontSize: 14, textAlign: "left" }} className="error_message">
                                {errors.firstname}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={6}>
                          <div className="Contact_Page_field ml-2">
                            <input
                              type="text"
                              id="userfirstname"
                              placeholder="Last Name*"
                              name="lastname"
                              className="contact_page_input"
                              value={values.lastname}
                              onChange={(e) => handleChange(e)}
                            />

                            {errors.lastname && touched.lastname && (
                              <div style={{ fontSize: 14, textAlign: "left" }} className="error_message">
                                {errors.lastname}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="Contact_Page_field">
                            <input
                              type="email"
                              id="useremail"
                              placeholder="Email Address*"
                              name="email"
                              className="contact_page_input"
                              value={values.email}
                              onChange={(e) => handleChange(e)}
                            />

                            {errors.email && touched.email && (
                              <div style={{ fontSize: 14, textAlign: "left" }} className="error_message">
                                {errors.email}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="Contact_Page_field">
                            <input
                              type="number"
                              id="userfirstname"
                              placeholder=" Phone Number*"
                              name="phone"
                              className="contact_page_input"
                              value={values.phone}
                              onChange={(e) => handleChange(e)}
                            />
                            {errors.phone && touched.phone && (
                              <div style={{ fontSize: 14, textAlign: "left" }} className="error_message">
                                {errors.phone}
                              </div>
                            )}
                          </div>
                        </Grid>
                        {state ? (
                          <Grid item xs={12} sm={12} lg={12}>
                            <div className="Contact_Page_field">
                              <input
                                type="text"
                                id="Inventory"
                                placeholder="Inventory"
                                name="inventory"
                                className="contact_page_input"
                                value={values.inventory}
                                onChange={(e) => handleChange(e)}
                              />
                            </div>
                          </Grid>
                        ) : null}
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="Contact_Page_field">
                            <input
                              type="text"
                              id="location"
                              placeholder="Location"
                              name="location"
                              className="contact_page_input"
                              value={values.location}
                              onChange={(e) => handleChange(e)}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="contact_grid_box_top Contact_Page_field">
                            <textarea
                              rows="5"
                              type="textarea"
                              id="message"
                              name="message"
                              className="contact_page_input_textarea"
                              value={values.message}
                              placeholder="Tell us what you're looking for and how we can help"
                              onChange={(e) => handleChange(e)}
                            />
                            {errors.message && touched.message && (
                              <div style={{ fontSize: 14, textAlign: "left" }} className="error_message">
                                {errors.message}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="contact_grid_box_top Contact_Page_field google_recaptch_box">
                            <ReCAPTCHA
                              ref={reCaptchaRef}
                              size="normal"
                              sitekey={ReCAPTCHA_sitekey}
                              onChange={(captchaCode) => {
                                setFieldValue("reCaptcha", captchaCode);
                                // onReCAPTCHAChange
                              }}
                            />
                            {errors.reCaptcha && touched.reCaptcha && (
                              <div style={{ fontSize: 14, textAlign: "left" }} className="error_message">
                                {errors.reCaptcha}
                              </div>
                            )}
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                          <div className="button_grid">
                            <button
                              type="submit"
                              disabled={isLoading}
                              className={isLoading ? "Submit_loading_btn" : "Submit_btn"}
                            >
                              Send Message
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          paddingY: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
          background: "#F5F5F5",
        }}
      >
        <Box className="container">
          <Grid container spacing={"24px"}>
            <Grid item md={12} lg={6}>
              <Box className="Who_We_Are_Title" color={"#141215"}>
                Exciting News: We’re Moving!
              </Box>
              <Box className="Our_Team_Banner_Sub_Title" marginTop={"30px"}>
                Marcel Equipment Limited is proud to announce that we’re relocating to a brand-new facility, opening in{" "}
                <b>July 2025</b>. This move represents our commitment to continuously improving how we serve our
                customers with a state-of-the-art space designed to support our work and meet your needs.
              </Box>
            </Grid>
            <Grid
              item
              lg={6}
              md={0}
              xl={0}
              sx={{
                display: {
                  xs: "none", // For extra small screens (mobile)
                  sm: "none", // For small screens (tablets)
                  md: "none", // For medium screens (laptops)
                  lg: "block", // For large screens (desktops)
                },
              }}
            >
              <Box
                className={"dashboard_banner"}
                sx={{
                  background: `url(${FacilityOne})`,
                  objectFit: "cover",
                  height: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  transform: "scaleX(-1)",
                }}
              />
            </Grid>
            <Grid item sm={12} md={6} lg={3}>
              <Process_Card item={Contact_US_Facility.New_Address} />
            </Grid>
            <Grid item sm={12} md={6} lg={3} style={{ width: "-webkit-fill-available" }}>
              <Process_Card item={Contact_US_Facility.Size} />
            </Grid>
            <Grid
              item
              lg={2}
              sx={{
                display: {
                  xs: "none", // For extra small screens (mobile)
                  sm: "none", // For small screens (tablets)
                  md: "none", // For medium screens (laptops)
                  lg: "block", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityTwo}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  transform: "scaleX(-1)",
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              sx={{
                display: {
                  xs: "none", // For extra small screens (mobile)
                  sm: "none", // For small screens (tablets)
                  md: "none", // For medium screens (laptops)
                  lg: "block", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityThree}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>
            <Grid
              item
              lg={2}
              sx={{
                display: {
                  xs: "none", // For extra small screens (mobile)
                  sm: "none", // For small screens (tablets)
                  md: "none", // For medium screens (laptops)
                  lg: "block", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityFour}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>

            <Grid
              item
              lg={0}
              md={12}
              xl={12}
              sx={{
                display: {
                  xs: "block", // For extra small screens (mobile)
                  sm: "block", // For small screens (tablets)
                  md: "block", // For medium screens (laptops)
                  lg: "none", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityOne}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  transform: "scaleX(-1)",
                }}
              />
            </Grid>
            <Grid
              item
              lg={0}
              md={4}
              sm={12}
              sx={{
                display: {
                  xs: "block", // For extra small screens (mobile)
                  sm: "block", // For small screens (tablets)
                  md: "block", // For medium screens (laptops)
                  lg: "none", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityTwo}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  transform: "scaleX(-1)",
                }}
              />
            </Grid>
            <Grid
              item
              lg={0}
              md={4}
              sm={12}
              sx={{
                display: {
                  xs: "block", // For extra small screens (mobile)
                  sm: "block", // For small screens (tablets)
                  md: "block", // For medium screens (laptops)
                  lg: "none", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityThree}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>
            <Grid
              item
              lg={0}
              md={4}
              sm={12}
              sx={{
                display: {
                  xs: "block", // For extra small screens (mobile)
                  sm: "block", // For small screens (tablets)
                  md: "block", // For medium screens (laptops)
                  lg: "none", // For large screens (desktops)
                },
              }}
            >
              <img
                src={FacilityFour}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Box position={"relative"}>
                  <video
                    ref={videoRef}
                    className="rounded-md shadow-lg"
                    width="100%"
                    height="100%"
                    onClick={togglePlayPause}
                    onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    poster={ThumbnailImage}
                    loop
                    playsInline
                    preload="auto"
                    src={
                      "https://vizybilitystorage.blob.core.windows.net/videos/DMSDb_MARCEL/facility.mp4?sp=r&st=2025-01-30T17:00:36Z&se=2030-01-31T18:29:36Z&spr=https&sv=2022-11-02&sr=b&sig=E8YaQodFu48EpCziWjZ1sVAsV2r3wrJIQj3KSIcU6cw%3D"
                    }
                    controls={isPlaying}
                  ></video>
                  {!isPlaying && (
                    <div
                      onClick={togglePlayPause}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        zIndex: 10,
                        cursor: "pointer",
                      }}
                    >
                      <img src={PlayIcon} alt="" width={"128px"} />
                    </div>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            className="Options_Sub_Title"
            textAlign={"center"}
            mb={"30px"}
            sx={{
              paddingTop: {
                xs: "40px", // For extra small screens (mobile)
                sm: "60px", // For small screens (tablets)
                md: "80px", // For medium screens (laptops)
                lg: "100px", // For large screens (desktops)
              },
            }}
          >
            What This Means for You
          </Box>
          <Grid container item spacing={4}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card item={Contact_US_Facility.State_Facilities} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card item={Contact_US_Facility.Expanded_Facilities} />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Card item={Contact_US_Facility.Room_Growth} />
            </Grid>
          </Grid>
          <Box className="explore_sub_title" mt={"50px"} mb={"30px"} textAlign={"center"}>
            While the location is changing, our dedication to delivering reliable, high-quality equipment and
            exceptional service remains the same. Stay tuned for more updates as we prepare to welcome you to our new
            home!
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          paddingY: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
          background: "#fff",
        }}
      >
        <Box className="container">
          <Box className="explore_title" textAlign={"center"}>
            Doing it Right Today… to Earn Your Business Tomorrow
          </Box>
          <Box marginTop={"20px"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
            <Button
              className="our_team"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path?.Category);
              }}
            >
              <span style={{ minWidth: "150px" }}>Explore Our Inventory</span>
            </Button>
            <Button
              className="about_us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.AboutUs);
              }}
            >
              <span style={{ width: "150px" }}>ABOUT US</span>
            </Button>
            <Button
              className="contact_us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.ContactUs);
              }}
              style={{ cursor: "pointer" }}
            >
              <span style={{ width: "150px" }}>CONTACT US</span>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ContactUsComponentV2;

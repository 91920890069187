const Btn = ({ label, active, onClick }) => {
  return (
    <>
      <button
        translate="no"
        className={active ? "header_language_active_btn" : "header_language_btn"}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
};
export default Btn;

import { Box } from "@mui/system";
import AboutUsBanner from "../../assets/img/images/Category.jpg";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllMachinesList, postMachinesList } from "../../services/machineService";
import { ApplicationId } from "../../api/url";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Path } from "../../Utils/ScreenPath";

const GridMachineBox = ({ bg, MachineDetails }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box className="MachineList_Grid_Box" position={"relative"} bgcolor={bg}>
        <Box className="MachineList_Grid_img">
          <img src={MachineDetails.primary_image.url} alt="" width={"100%"} />
        </Box>
        <Box className="MachineList_Grid_dis">
          <Box>
            <Box className="MachineList_Title_Box" textAlign={"start"}>
              <Box
                className="MachineList_Grid_Title"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  const encodeURICom = encodeURIComponent(
                    `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
                  );
                  navigate(`${Path.Inventory}/${encodeURICom}`, {
                    state: MachineDetails,
                  });
                }}
              >
                {/* {MachineDetails.manufacturer?.name} */}
                {MachineDetails?.model?.manufacturer?.name} {MachineDetails?.model?.number}{" "}
                {MachineDetails?.year ? `(${MachineDetails?.year})` : null}{" "}
                {MachineDetails?.unit_number ? ` UNIT # ${MachineDetails?.unit_number}` : null}
              </Box>
              {MachineDetails.meter_reading ? (
                <Box className="MachineList_Grid_Hours_Title">
                  {t("Dashboard_Content_MACHINE_HOURS")}: {MachineDetails.meter_reading}
                </Box>
              ) : (
                <Box className="MachineList_Grid_Hours_Title">{t("Dashboard_Content_MACHINE_HOURS")}: N/A</Box>
              )}
            </Box>
            <Box>
              <Box className="MachineList_Grid_description" textAlign={"start"}>
                {MachineDetails.description}

                <div
                  dangerouslySetInnerHTML={{
                    __html: MachineDetails?.inlist_description_html,
                  }}
                ></div>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              className="MachineList_Click_Grid_here_Text"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                const encodeURICom = encodeURIComponent(
                  `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
                );
                navigate(`${Path.Inventory}/${encodeURICom}`, {
                  state: MachineDetails,
                });
              }}
            >
              {t("Dashboard_Content_Click_here")}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const CategoryComponentV2 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await getAllMachinesList();
        const categoryDetails = response?.data.find((item) => item.AssetTypeId === 2)?.CategoryDetails || [];

        const desiredOrder = [38, 40, 39, 23, 44, 36, 35, 41];

        const filteredCategories = [];
        categoryDetails.forEach((item) => {
          const indexInDesiredOrder = desiredOrder.indexOf(item?.CategoryId);
          if (indexInDesiredOrder !== -1) {
            // If the item is found in the desiredOrder, add it to filteredCategories
            filteredCategories[indexInDesiredOrder] = item;
          }
        });

        const sortedCategories = filteredCategories;
        const sloganMap = {
          23: "Efficient and versatile, our wheel loaders are reconditioned to streamline material handling and maximize productivity.",
          35: "Our reconditioned and work-ready articulated trucks are ready to tackle any job site.",
          36: "Built for durability, our reconditioned rock trucks excel in moving heavy loads across rugged terrains.",
          38: "Maximize efficiency in landfill operations with work-ready compactors reconditioned and/or rebuilt for durability and performance.",
          39: "Our crawler dozers are reconditioned and equipped to handle your most demanding grading and leveling tasks.",
          40: "Specialized dozers that are reconditioned and/or rebuilt to manage tough waste environments with ease and reliability.",
          44: "Heavy-duty block handlers designed to lift and transport even the heaviest materials with ease.",
        };

        const BtnName = {
          23: "Discover More Wheel Loaders",
          35: "Browse All Articulated Trucks",
          36: "Explore More ROCK TRUCKS",
          38: "Explore More Landfill Compactors",
          39: "View All Crawler Dozers",
          40: "Browse All Waste Handler Dozers",
          44: "See All Block Handlers",
        };

        const data = await Promise.all(
          sortedCategories?.map(async (item) => {
            const payload = {
              PageSize: 4,
              PageNumber: 0,
              SortColumn: "SortOrder ASC",
              CategoryId: item.CategoryId,
              ApplicationId: ApplicationId,
            };

            const machineList = await postMachinesList(payload);

            return {
              ...item,
              id: item.CategoryName,
              link: `/${item.CategoryId}-${item.CategoryName.toLowerCase().replaceAll(" ", "-")}`,
              slogan: sloganMap[item.CategoryId] || "",
              BtnName: BtnName[item.CategoryId] || "",
              MachineList: machineList?.data || [],
            };
          })
        );

        setMachineData(data);
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box
        style={{
          // background: `url(${AboutUsBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${AboutUsBanner}) center/cover no-repeat`,
        }}
        className="bg-[image:var(--image-url)]"
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Title">
            EXPLORE WORK READY EQUIPMENT THAT WORKS AS HARD AS YOU DO <br />
            <h100 className="Our_Team_Banner_Sub_Title" style={{ fontWeight: "bold", textTransform: "none" }}>
              From reconditioned dependable workhorses to completely rebuilt units, find heavy equipment built to meet
              your toughest demands.
            </h100>
            <br />
            <Button
              className="our_team"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate("/38-landfill-compactors");
              }}
            >
              <h100 style={{ minWidth: "150px" }}>Browse Categories</h100>
            </Button>
            <Button
              className="contact_us_team"
              style={{ cursor: "pointer" }}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path?.ContactUs);
              }}
            >
              <h100 style={{ width: "150px" }}>Contact Our Team</h100>
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        className="Our_Team_Banner_Sub_Box"
        sx={{
          paddingY: {
            xs: "40px", // For extra small screens (mobile)
            sm: "60px", // For small screens (tablets)
            md: "80px", // For medium screens (laptops)
            lg: "100px", // For large screens (desktops)
          },
        }}
      >
        <Box className="container">
          <Box className="Our_Team_Banner_Sub_Title">
            At Marcel Equipment Limited, we know that the right machine can make all the difference. That’s why our
            inventory features a carefully selected range of reconditioned and/or rebuilt heavy equipment, each piece
            completed to deliver performance you can rely on. Whether you’re tackling landfill operations, construction
            projects, or mining challenges, you’ll find equipment designed to help you succeed. Explore our categories
            to get started or reach out to our team for expert guidance.
          </Box>
        </Box>
      </Box>

      {isLoading && (
        <Box
          className="Our_Team_Banner_Sub_Box"
          sx={{
            paddingY: {
              xs: "40px", // For extra small screens (mobile)
              sm: "60px", // For small screens (tablets)
              md: "80px", // For medium screens (laptops)
              lg: "100px", // For large screens (desktops)
            },
          }}
          textAlign={"center"}
        >
          <Box className="container" textAlign={"center"}>
            <div
              className="spinner-container"
              style={{ textAlign: "center", display: "flex", justifyContent: "center" }}
            >
              <div className="loading-spinner"></div>
            </div>
          </Box>
        </Box>
      )}
      {console.log("machineData", machineData)}
      {!isLoading &&
        machineData?.map((item, index) => {
          return (
            <Box
              id={item?.CategoryName}
              className="Our_Team_Banner_Sub_Box"
              sx={{
                paddingY: {
                  xs: "40px", // For extra small screens (mobile)
                  sm: "60px", // For small screens (tablets)
                  md: "80px", // For medium screens (laptops)
                  lg: "100px", // For large screens (desktops)
                },
              }}
              textAlign={"center"}
              bgcolor={index % 2 === 0 ? "#F5F5F5" : "#fff"}
            >
              <Box className="container" textAlign={"center"}>
                <Box className="Our_Team_Members_Text" textAlign={"center"}>
                  {item.CategoryName}
                </Box>

                <Box className="Our_Team_Banner_Sub_Title" textAlign={"center"} marginTop={"20px"}>
                  {item.slogan}
                </Box>

                <Grid container item spacing={"24px"} mt={"16px"} mb={"40px"} justifyContent={"center"}>
                  {item?.MachineList?.machines.map((MachineDetails) => {
                    return (
                      <Grid item xs={12} md={6} lg={3}>
                        <GridMachineBox MachineDetails={MachineDetails} bg={index % 2 === 0 ? "#fff" : "#F5F5F5"} />
                      </Grid>
                    );
                  })}
                </Grid>

                <Button
                  className="about_us"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    navigate(item?.link);
                  }}
                >
                  {item?.BtnName}
                </Button>
              </Box>
            </Box>
          );
        })}

      <Box sx={{ background: "#ffffff" }}>
        <Box
          className="container"
          sx={{
            paddingY: {
              xs: "40px", // For extra small screens (mobile)
              sm: "60px", // For small screens (tablets)
              md: "80px", // For medium screens (laptops)
              lg: "100px", // For large screens (desktops)
            },
          }}
        >
          <Box className="explore_title" textAlign={"center"}>
            Learn More About What Makes Marcel Different
          </Box>
          <Box marginTop={"20px"} display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
            <Button
              className="our_team"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.ReconditioningProcess);
              }}
            >
              <h100 style={{ minWidth: "150px" }}>Our Reconditioning Process</h100>
            </Button>
            <Button
              className="about_us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.AboutUs);
              }}
            >
              <h100 style={{ width: "150px" }}>ABOUT US</h100>
            </Button>
            <Button
              className="contact_us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                navigate(Path.ContactUs);
              }}
              style={{ cursor: "pointer" }}
            >
              <h100 style={{ width: "150px" }}>CONTACT US</h100>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CategoryComponentV2;

import { Box, Button, Grid } from "@mui/material";
import { Reconditioning_Process } from "../../../Utils/Constants";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../Utils/ScreenPath";

const Card = ({ item }) => {
  return (
    <Box className="Reconditioning_Option_Card">
      <Box display={"flex"}>
        <Box className="Process_Card_Index">{item.index}</Box>
      </Box>
      <Box className="Process_Card_Title" paddingLeft={"0px"}>
        {item.title}
      </Box>
      <Box className="Process_Card_Sub_Title" paddingLeft={"0px"}>
        {item.subTitle}
      </Box>
    </Box>
  );
};

const ReconditioningOption = () => {
  let navigate = useNavigate();
  return (
    <>
      <Box className="explore_title" textAlign={"center"}>
        Rebuild and Reconditioning Options
      </Box>
      <Box className="explore_sub_title" mt={"30px"} mb={"30px"} textAlign={"center"}>
        Similar to the definition of "rebuilt," the scope of work in rebuilding and reconditioning varies. The best
        programs include a rigorous process that involves a complete teardown of the machine and examination of all
        major systems: electrical, hydraulic, drivetrain, structural components, operator station, hoses, and cylinders.
        This comprehensive approach is what Marcel Equipment implements in all their Rebuild & Reconditioning options.
      </Box>
      <Box className="Options_Sub_Title" mt={"50px"} textAlign={"center"} mb={"30px"}>
        We offer a range of solutions tailored to our customers' specific needs
      </Box>
      <Grid container item spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card item={Reconditioning_Process.Marcel_Equipment} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card item={Reconditioning_Process.Certified_Rebuild} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Card item={Reconditioning_Process.Provide_Extended} />
        </Grid>
        <Grid item xs={12}>
          <Box className="customizable_services_text">
            {" "}
            These customizable services ensure that each client's equipment is restored to peak performance and
            dependability, aligning with their unique requirements.
          </Box>
        </Grid>
      </Grid>
      <Box textAlign={"center"} marginTop={"50px"}>
        <Button
          className="our_team"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            navigate(Path?.Category);
          }}
        >
          <span>Explore Our Inventory</span>
        </Button>
      </Box>
    </>
  );
};
export default ReconditioningOption;

import { Box } from "@mui/system";
import { Dashboard_Content } from "../../../Utils/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { Path } from "../../../Utils/ScreenPath";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { postMachinesList } from "../../../services/machineService";
import { ApplicationId } from "../../../api/url";
import { Img } from "react-image";
import CopyIcon from "../../../assets/img/images/copy.png";
import CheckList from "../../../assets/img/svg/checkList.svg";
import GridIcon from "../../../assets/img/svg/Grid_Icon.svg";
import SelectIcon from "../../../assets/img/svg/select-icon.svg";
import { Grid } from "@mui/material";

export const MachineBoxUseCopy = ({ notShowCheckBox, MachineDetails, selecedEquipment, setSelecedEquipment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const encodeURICom = encodeURIComponent(
    `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
  );

  const RPath = `${Path.Inventory}/${encodeURICom}`;
  return (
    <>
      <table className="MachineList_Box">
        <tr>
          <td>
            <Img src={MachineDetails.primary_image.url} width={150} />
          </td>
          <td>
            <table>
              <tr>
                <td>
                  <table className="MachineList_Title_Box">
                    <tr>
                      <td
                        // style={{ fontWeight: "bold" }}
                        className="MachineList_Title MachineList_Title_Hover"
                      >
                        <a href={RPath} style={{ fontWeight: "400" }}>
                          {MachineDetails?.model?.manufacturer?.name} {MachineDetails?.model?.number}{" "}
                          {MachineDetails?.year ? `(${MachineDetails?.year})` : null}{" "}
                          {MachineDetails?.unit_number ? ` UNIT # ${MachineDetails?.unit_number}` : null}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td
                        // style={{ fontWeight: "bold" }}
                        className="MachineList_Title"
                      >
                        <a href={RPath} style={{ textDecoration: "none", color: "#000", fontWeight: "400" }}>
                          {MachineDetails.meter_reading
                            ? `${t("Dashboard_Content_MACHINE_HOURS")}: ${MachineDetails.meter_reading}`
                            : `${t("Dashboard_Content_MACHINE_HOURS")}: N/A`}
                        </a>
                      </td>
                    </tr>
                  </table>
                  <table>
                    <tr>
                      <td
                        className="MachineList_description"
                        // style={{ fontSize: "12px" }}
                      >
                        {MachineDetails.description}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: MachineDetails?.inlist_description_html,
                          }}
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td
                        // style={{ fontWeight: "bold" }}
                        className="MachineList_Title"
                        style={{ textAlign: "right", fontSize: "16px" }}
                      >
                        <a href={RPath}>{t("Dashboard_Content_Click_here")}</a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </>
  );
};

export const MachineBox = ({
  notShowCheckBox,
  MachineDetails,
  selecedEquipment,
  setSelecedEquipment,
  selectedCheckList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box className="MachineList_Box">
        {!notShowCheckBox && (
          <Box display={"flex"} alignItems={"center"}>
            {selectedCheckList && (
              <input
                type="checkbox"
                className="checkbox"
                checked={selecedEquipment?.find((item) => item.id === MachineDetails.id) || false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setSelecedEquipment([...selecedEquipment, MachineDetails]);
                  } else {
                    const newData = selecedEquipment?.filter((item) => item.id !== MachineDetails.id);
                    setSelecedEquipment(newData);
                  }
                }}
              />
            )}
          </Box>
        )}
        <Box className="MachineList_img">
          <img src={MachineDetails.primary_image.url} alt="" width={"100%"} />
        </Box>
        <Box className="MachineList_dis">
          <Box>
            <Box className="MachineList_Title_Box">
              <Box
                className="MachineList_Title MachineList_Title_Hover"
                onClick={() => {
                  if (!notShowCheckBox) {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    const encodeURICom = encodeURIComponent(
                      `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(
                        " ",
                        "-"
                      )}`
                    );
                    navigate(`${Path.Inventory}/${encodeURICom}`, {
                      state: MachineDetails,
                    });
                    // window.location.reload();
                  }
                }}
              >
                {/* {MachineDetails.manufacturer?.name} */}
                {MachineDetails?.model?.manufacturer?.name} {MachineDetails?.model?.number}{" "}
                {MachineDetails?.year ? `(${MachineDetails?.year})` : null}{" "}
                {MachineDetails?.unit_number ? ` UNIT # ${MachineDetails?.unit_number}` : null}
              </Box>
              {MachineDetails.meter_reading ? (
                <Box className="MachineList_Title">
                  {t("Dashboard_Content_MACHINE_HOURS")}: {MachineDetails.meter_reading}
                </Box>
              ) : (
                <Box className="MachineList_Title">{t("Dashboard_Content_MACHINE_HOURS")}: N/A</Box>
              )}
            </Box>
            <Box>
              <Box className="MachineList_description">
                {MachineDetails.description}

                <div
                  dangerouslySetInnerHTML={{
                    __html: MachineDetails?.inlist_description_html,
                  }}
                ></div>
                {/* <div className='EquipmentCard_DETAIL_Box'>
                  <div className='EquipmentCard_DETAILS'>DETAILS:</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.manufacturer?.name}</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.model?.number}</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.year}</div>
                  <div className='EquipmentCard_DETAILS'>{MachineDetails?.meter_reading}</div>
                </div> */}
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              className="MachineList_Click_here_Text"
              onClick={() => {
                if (!notShowCheckBox) {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  const encodeURICom = encodeURIComponent(
                    `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
                  );
                  navigate(`${Path.Inventory}/${encodeURICom}`, {
                    state: MachineDetails,
                  });
                  // window.location.reload();
                }
              }}
            >
              {t("Dashboard_Content_Click_here")}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const GridMachineBox = ({
  notShowCheckBox,
  MachineDetails,
  selecedEquipment,
  setSelecedEquipment,
  selectedCheckList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Box className="MachineList_Grid_Box" position={"relative"} bgcolor={"#FFFFFF"}>
        {!notShowCheckBox && (
          <Box display={"flex"} alignItems={"center"} position={"absolute"}>
            {selectedCheckList && (
              <input
                type="checkbox"
                className="checkbox"
                checked={selecedEquipment?.find((item) => item.id === MachineDetails.id) || false}
                onClick={(e) => {
                  if (e.target.checked) {
                    setSelecedEquipment([...selecedEquipment, MachineDetails]);
                  } else {
                    const newData = selecedEquipment?.filter((item) => item.id !== MachineDetails.id);
                    setSelecedEquipment(newData);
                  }
                }}
              />
            )}
          </Box>
        )}
        <Box className="MachineList_Grid_img">
          <img src={MachineDetails.primary_image.url} alt="" width={"100%"} />
        </Box>
        <Box className="MachineList_Grid_dis">
          <Box>
            <Box className="MachineList_Title_Box" textAlign={"start"}>
              <Box
                className="MachineList_Grid_Title"
                onClick={() => {
                  if (!notShowCheckBox) {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    const encodeURICom = encodeURIComponent(
                      `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(
                        " ",
                        "-"
                      )}`
                    );
                    navigate(`${Path.Inventory}/${encodeURICom}`, {
                      state: MachineDetails,
                    });
                    // window.location.reload();
                  }
                }}
              >
                {/* {MachineDetails.manufacturer?.name} */}
                {MachineDetails?.model?.manufacturer?.name} {MachineDetails?.model?.number}{" "}
                {MachineDetails?.year ? `(${MachineDetails?.year})` : null}{" "}
                {MachineDetails?.unit_number ? ` UNIT # ${MachineDetails?.unit_number}` : null}
              </Box>
              {MachineDetails.meter_reading ? (
                <Box className="MachineList_Grid_Hours_Title">
                  {t("Dashboard_Content_MACHINE_HOURS")}: {MachineDetails.meter_reading}
                </Box>
              ) : (
                <Box className="MachineList_Grid_Hours_Title">{t("Dashboard_Content_MACHINE_HOURS")}: N/A</Box>
              )}
            </Box>
            <Box>
              <Box className="MachineList_Grid_description">
                {MachineDetails.description}

                <div
                  dangerouslySetInnerHTML={{
                    __html: MachineDetails?.inlist_description_html,
                  }}
                ></div>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              className="MachineList_Click_Grid_here_Text"
              onClick={() => {
                if (!notShowCheckBox) {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  const encodeURICom = encodeURIComponent(
                    `${MachineDetails.assetAdvertisementId}-${MachineDetails?.primary_image?.name.replaceAll(" ", "-")}`
                  );
                  navigate(`${Path.Inventory}/${encodeURICom}`, {
                    state: MachineDetails,
                  });
                }
              }}
            >
              {t("Dashboard_Content_Click_here")}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const MachineListComponent = ({
  copyMachine,
  setCopyMachine,
  selecedEquipment,
  selectCategoryType,
  isMachineData,
  setSelecedEquipment,
  selectedCheckList,
  setSelectedCheckList,
}) => {
  const [gridView, setGridView] = useState(true);
  return (
    <>
      <Box className="MachineList_Category_box">
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} marginBottom={"10px"}>
          <Box className="MachineList_V2_Category_title">{selectCategoryType?.CategoryName}</Box>

          <Box display={"flex"}>
            <Box
              bgcolor={"#141215"}
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              width={"40px"}
              height={"40px"}
              justifyContent={"center"}
              marginRight={"10px"}
              onClick={() => setSelectedCheckList(!selectedCheckList)}
            >
              <img src={SelectIcon} alt="" />
            </Box>

            <Box
              bgcolor={"#141215"}
              display={"flex"}
              alignItems={"center"}
              sx={{ cursor: "pointer" }}
              width={"40px"}
              height={"40px"}
              justifyContent={"center"}
              onClick={() => setGridView(!gridView)}
            >
              <img src={GridIcon} alt="" />
            </Box>
          </Box>
        </Box>

        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          {selectedCheckList && (
            <Box display={"flex"} fontWeight={"bold"} alignItems={"center"} marginBottom={"10px"}>
              <input
                className="checkbox"
                type="checkbox"
                style={{ margin: gridView ? "0px 10px 0px 5px" : "3px 7px 0px 13px" }}
                checked={isMachineData.length !== 0 && selecedEquipment.length === isMachineData.length}
                onChange={() => {
                  if (selecedEquipment.length === isMachineData.length) {
                    setSelecedEquipment([]);
                  } else {
                    setSelecedEquipment(isMachineData);
                  }
                }}
              />
              <span className="translate-text">Select All</span>
            </Box>
          )}

          {selecedEquipment.length > 0 && selectedCheckList && (
            <Box ml={"10px"} mr={"10px"} sx={{ cursor: "pointer" }} className="d-flex" marginBottom={"10px"}>
              <img src={CopyIcon} alt="" width={"20px"} onClick={() => setCopyMachine(!copyMachine)} />
            </Box>
          )}
        </Box>
        {gridView ? (
          <Grid container item spacing={"24px"} mt={"0px"} sx={{ borderTop: "1px solid #eee" }}>
            {isMachineData.map((MachineDetails) => {
              return (
                <Grid item xs={12} md={6} lg={4}>
                  <GridMachineBox
                    selecedEquipment={selecedEquipment}
                    MachineDetails={MachineDetails}
                    setSelecedEquipment={setSelecedEquipment}
                    selectedCheckList={selectedCheckList}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Box className="d-flex flex-col">
            {isMachineData.map((MachineDetails) => {
              return (
                <MachineBox
                  selecedEquipment={selecedEquipment}
                  MachineDetails={MachineDetails}
                  setSelecedEquipment={setSelecedEquipment}
                  selectedCheckList={selectedCheckList}
                />
              );
            })}
          </Box>
        )}
      </Box>
    </>
  );
};
export default MachineListComponent;

import { Box, Grid } from "@mui/material";
import { Gallery_List } from "../../Utils/Constants";

const GalleryComponent = () => {
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {Gallery_List?.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <a href={item.link} target="_blank" className="" rel="noreferrer">
                  <Box
                    className="Gallery_Link_Box"
                    style={{
                      backgroundImage: `url(${item.logo})`,
                    }}
                  ></Box>
                </a>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};
export default GalleryComponent;

import { useEffect } from "react";
import DashboardLayout from "../../Layout/DashboardLayout/DashboardLayout";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import CategoryComponentV2 from "../../components/CategoryComponentV2/CategoryComponentV2";
const CategoryV2 = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-VWGPB9LR1K", // Replace with your Google Tag Manager ID
      dataLayerName: "PageDataLayer",
      dataLayer: {
        page: window.location.pathname,
      },
    };
    TagManager.initialize(tagManagerArgs);
  }, []);
  return (
    <>
      <Helmet>
        <title>Marcel Equipment Limited | Reconditioned Used Equipment</title>
        <meta
          name="description"
          content="Marcel Equipment Limited offers high-quality used heavy equipment, rebuilt & reconditioned for top performance in construction and landfill industries."
        />
      </Helmet>
      <DashboardLayout activeTab={"Category"}>
        <CategoryComponentV2 />
      </DashboardLayout>
    </>
  );
};
export default CategoryV2;
